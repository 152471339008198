import React, {useContext} from "react";
import {RoomWrapperButtonsGames} from "./RoomWrapperButtonsGames";
import {Quiz} from "./Quiz/Quiz";
import {Roulette} from "./Roulette/Roulette";
import {RoomContext} from "../context/RoomContext";

export const RoomGames = () => {
    const {isActiveQuiz, isActiveRoulette} = useContext(RoomContext);

    return (
        <React.Fragment>
            <RoomWrapperButtonsGames />

            {isActiveQuiz && <Quiz />}
            {isActiveRoulette && <Roulette />}
        </React.Fragment>
    );
}