import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {Home} from "../pages/Home";
import {Room} from "../pages/Room";
import {RoomProvider} from "../context/RoomContext";
import {JitsiRoom} from "./JitsiRoom";

const RouterComponent = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/jitsi-room" element={<JitsiRoom />} />
            <Route path="/room/:id" element={<RoomProvider > <Room /> </RoomProvider>} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default RouterComponent;