import http from "./index";

// получить типы roulette
export const getRouletteTypesRequest = async () => {
    try {
        const res = await http.get('roulette-types');
        return res.data;
    } catch (err) {
        throw err;
    }
}