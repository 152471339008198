export const setOneRoom = (room) => ({
    type: 'SET_ROOM',
    payload: room,
});

export const addConnectedUserToOneRoom = (user) => ({
    type: 'ADD_CONNECTED_USER',
    payload: {
        user
    }
});

export const removeConnectedUserFromOneRoom = (user_id) => ({
    type: 'REMOVE_CONNECTED_USER',
    payload: {
        user_id
    }
});

export const updateOneRoom = (data) => ({
    type: 'UPDATE_ROOM',
    payload: data
});

export const updateRoomAccessOneRoom = (data) => ({
    type: 'UPDATE_ROOM_ACCESS',
    payload: data
});

export const addUserToBlackList = (user_id) => ({
    type: 'ADD_USER_TO_BLACK_LIST',
    payload: user_id
});

export const removeUserFromBlackList = (user_id) => ({
    type: 'REMOVE_USER_FROM_BLACK_LIST',
    payload: user_id
});

export const removeOneRoom = () => ({
    type: 'REMOVE_ROOM',
});

export const startLoading = () => ({
    type: 'START_LOADING'
});

export const stopLoading = () => ({
    type: 'STOP_LOADING'
});