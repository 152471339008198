import {Header} from "../components/Header";
import {Filter} from "../components/Filter";
import React, {useContext, useEffect} from "react";
import {WSContext} from "../context/WSContext";
import {ModalForUserNotAuth} from "../components/modals/ModalForUserNotAuth";
import {ModalInfo} from "../components/modals/ModalInfo";
import {useDispatch, useSelector} from "react-redux";
import {Wechat} from "react-bootstrap-icons";
import {useWebSocketHome} from "../hooks/useWebSocketHome";
import {setRooms, startLoading, stopLoading} from "../redux/actions/room";
import {getRooms} from "../axios/roomRequests";
import {ModalDeleteRoom} from "../components/modals/ModalDeleteRoom";
import {useNavigate} from "react-router-dom";
import {useCloseModal} from "../hooks/useCloseModal";
import {ListRooms} from "../components/ListRooms";

export const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { ws } = useContext(WSContext);
    const user = useSelector(state => state.user);
    const rooms = useSelector(state => state.rooms);
    useCloseModal();

    useEffect(() => {
        const getRoomsData = async () => {
            dispatch(startLoading());
            try {
                const res = await getRooms();
                dispatch(setRooms(res));
                dispatch(stopLoading());
            } catch (err) {
                console.log(err);
                dispatch(stopLoading());
            }
        }
        getRoomsData();
    }, [dispatch]);

    useEffect(() => {
        if (ws) {
            ws.emit("home-connect");
        }
    }, [ws]);

    useEffect(() => {
        return () => {
            if (ws) {
                ws.emit("home-leave-home-page");
            }
        };
    }, [ws]);

    useWebSocketHome();

    // const goToTestPlayer= () => {
    //     navigate('/jitsi-room');
    // }

    return (
        <div className='container'>
            <Header />
            <Filter />
            {/*<ButtonBirch*/}
            {/*    text='Player'*/}
            {/*    onClick={goToTestPlayer}*/}
            {/*/>*/}
            {/*<ButtonBirch*/}
            {/*    text='Jitsi Meet'*/}
            {/*    onClick={goToTestPlayer}*/}
            {/*/>*/}
            <ListRooms />
            {user.id &&
                <div className="home-social-button d-flex align-items-center justify-content-center pointer"
                     data-bs-toggle="offcanvas" data-bs-target="#offcanvasSocial" aria-controls="offcanvasSocial">
                    <Wechat size={30} />
                </div>
            }

            <ModalForUserNotAuth />
            <ModalInfo />
            {rooms.data.map((room) => (
                <div key={room.room_id}>
                    {user.id === room.owner.id && (
                        <ModalDeleteRoom room_id={room.room_id}/>
                    )}
                </div>
            ))}
        </div>
    );
};