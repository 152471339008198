const initialState = [];

export const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_MESSAGE':
            return [
                ...state,
                {
                    message: action.payload.message,
                    type: action.payload.type,
                    room_id: action.payload.room_id,
                    user_id: action.payload.user_id,
                    name: action.payload.name,
                    avatar: action.payload.avatar,
                }
            ];
        case 'SET_MESSAGES':
            return action.payload;
        default:
            return state;
    }
};