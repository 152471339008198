import {ChatDots} from "react-bootstrap-icons";
import {PopoverSocialConfirmUnfollow} from "../PopoverSocialConfirmUnfollow";
import {SocialChat} from "./SocialChat";
import React from "react";

export const SocialPeopleList = ({data, index, toggleOpenChat, openChats, privateChatById}) => {

    return (
        <div>
            <div className="d-flex align-items-center social-list-people">
                <div className="me-auto d-flex align-items-center">
                    <img
                        src={data.avatar}
                        alt={`Avatar`}
                        className="img-fluid rounded-circle pointer"
                    />
                    <p className="mb-0 ms-2">{data.name}</p>
                </div>
                <div className="ms-auto d-flex align-items-center">
                    {data.is_friend &&
                        <div className="me-2 pointer" onClick={() => toggleOpenChat(`all${index}`)}>
                            <ChatDots size={20}/>
                        </div>
                    }

                    <div className="">
                        <PopoverSocialConfirmUnfollow id={data.id} name={data.name}/>
                    </div>
                </div>
            </div>
            {(data.is_friend && openChats[`all${index}`]) &&
                <SocialChat index={`all${index}`}
                            isOpen={openChats[`all${index}`]}
                            toggleOpen={() => toggleOpenChat(`all${index}`)}
                            chat={privateChatById[data.id]?.messages}
                            userInfo={data}
                />
            }
        </div>
    );
}