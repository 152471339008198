import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {RoomContext} from "../../context/RoomContext";
import {useSelector} from "react-redux";
import Countdown from "react-countdown";

export const QuizInProgress = () => {
    const user = useSelector(state => state.user);
    const {quizMap, usersQuiz, setUsersQuiz, quiz} = useContext(RoomContext);
    const [numberQuestion, setNumberQuestion] = useState(1);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [isAnswered, setIsAnswered] = useState(false);
    const [localFinish, setLocalFinish] = useState(false);

    const initTime = 21;
    const initScore = 1000;
    const waiting = 1000;
    const correctAnswer = quiz[numberQuestion - 1].correct_answer;
    const [startTimer, setStartTimer] = useState(Date.now() + (initTime * 1000));
    const timerRef = useRef();
    const updateTimer = () => {
        setStartTimer(Date.now() + (initTime * 1000))
    }

    useEffect(() => {
        return () => {
            clearInterval(timerRef.current);
        };
    }, []);

    useEffect(() => {
        const meFromQuiz = usersQuiz.find(currentUser => currentUser.id === user.id);
        if (meFromQuiz) setNumberQuestion(meFromQuiz.numberQuestion);
    }, []);

    const nextQuestion = useCallback((answer) => {
        setSelectedAnswer(answer);
        setIsAnswered(true);

        const finish = (numberQuestion === 10);
        let currentScore = 0;
        const isCorrectAnswer = (answer === correctAnswer);

        if (isCorrectAnswer) {
            currentScore = Math.round((timerRef.current.state.timeDelta.total / (initTime * 1000)) * initScore);
        }

        const updatedUsersQuiz = usersQuiz.map(currentUser => {
            if (currentUser.id === user.id) {
                return {
                    ...currentUser,
                    score: currentUser.score + currentScore,
                    numberQuestion: (finish) ? currentUser.numberQuestion : currentUser.numberQuestion + 1,
                    finish: finish
                };
            }
            return currentUser;
        });
        const allFinished = updatedUsersQuiz.every(user => user.finish === true);

        clearInterval();
        clearInterval(timerRef.current);
        updateTimer();
        if (!finish) timerRef.current.start();
        setTimeout(() => {
            setSelectedAnswer(null);
            setIsAnswered(false);

            if (finish) setLocalFinish(finish);
            if (finish) setStartTimer(null);
            if (!finish) {
                setNumberQuestion(prevNumber => prevNumber + 1);
            }
            if (allFinished) {
                quizMap.current.set('quiz', {
                    ...quizMap.current.get('quiz'),
                    usersQuiz: updatedUsersQuiz,
                    stepQuiz: 'finish'
                });
            } else {
                quizMap.current.set('quiz', {
                    ...quizMap.current.get('quiz'),
                    usersQuiz: updatedUsersQuiz
                });
            }

        }, waiting);
    });


    const renderColorBtn = (option) => {
        if (!isAnswered) return 'btn-secondary';

        if (option === correctAnswer) {
            return 'btn-success';
        }

        if (option === selectedAnswer) {
            return 'btn-danger';
        }

        return 'btn-secondary';
    };

    const isUserInQuiz = () => {
        return usersQuiz.some(userInQuiz => userInQuiz.id === user.id);
    };

    const renderer = ({ seconds, total, completed }) => {
        if (completed) {
            return nextQuestion();
        } else {
            return <div>
                <p>Remaining time: {seconds}</p>
                <div className="d-flex justify-content-center">
                    <div className="progress mb-4 w-75">
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${(total / (initTime * 1000)) * 100}%` }}
                            aria-valuenow={(total / (initTime * 1000)) * 100}
                            aria-valuemin="0"
                            aria-valuemax="100">
                        </div>
                    </div>
                </div>
            </div>;
        }
    };

    return (
        <div className="d-flex flex-column quiz-step-join">
            {(isUserInQuiz() && !localFinish) ?
                <div className="">
                    <div className="text-center text-white">
                        <h3 onClick={() => console.log(timerRef.current.state.timeDelta.milliseconds)}>{quiz[numberQuestion - 1].question}</h3>
                        <p>Step: {numberQuestion}/10</p>
                        <Countdown ref={timerRef} date={startTimer} precision={3} renderer={renderer}/>
                    </div>

                    <div className="container mt-3">
                        <div className="row">
                            {quiz[numberQuestion - 1].options.map((option, index) => (
                                <div className="col-6 mb-3" key={index}>
                                    <button
                                        onClick={() => nextQuestion(option)}
                                        className={`btn w-100 ${renderColorBtn(option)}`}
                                        type="button"
                                        disabled={isAnswered}
                                    >
                                        {option}
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
                :
                <div className="d-flex justify-content-center">
                    <p className="quiz-waiting-users">Waiting other users...</p>
                </div>
            }
            <div className="d-flex justify-content-center">
                <div className="wrapper-wight-content">
                    {usersQuiz
                        .slice() // Создаем копию массива, чтобы не мутировать исходный
                        .sort((a, b) => b.score - a.score) // Сортируем по убыванию score (от большего к меньшему)
                        .map((currentUser, index) => (
                            <div
                                className={`d-flex align-items-center invite-user-content p-2 text-white border-bottom ${currentUser.id === user.id && 'quiz-progress-score-me'}`}
                                key={currentUser.id}
                            >
                                <div className="border-right mx-1">
                                    <p className="position-quiz text-center">{index + 1}</p>
                                </div>
                                <img src={currentUser.avatar} alt="avatar" className="me-2" />
                                <p className="mb-0 mx-2">{currentUser.name}</p>
                                <p className="mb-0">{currentUser.score}</p>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}