const initialState = {
    follows: []
};

export const followsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FOLLOWS':
            return {
                ...state,
                follows: action.payload.map(user => ({
                    id: user.id,
                    followers: user.followers,
                    following: user.following,
                    friends: user.friends,
                    followers_count: user.followers.length,
                    following_count: user.following.length,
                    friends_count: user.friends.length,
                    is_following: false,
                    is_friend: false,
                }))
            };

        case 'FOLLOW_USER':
            return {
                ...state,
                follows: state.follows.map(user => {
                    if (user.id === action.payload.follower.id) {
                        const isFriend = user.following.some(following => following.id === action.payload.following.id);
                        const newFollowers = [
                            ...user.followers,
                            {
                                id: action.payload.following.id,
                                name: action.payload.following.name,
                                avatar: action.payload.following.avatar,
                                country: action.payload.following.country
                            }
                        ];
                        const newFriends = isFriend ? [
                            ...user.friends,
                            {
                                id: action.payload.following.id,
                                name: action.payload.following.name,
                                avatar: action.payload.following.avatar,
                                country: action.payload.following.country
                            }
                        ] : user.friends;
                        return {
                            ...user,
                            followers: newFollowers,
                            followers_count: newFollowers.length,
                            friends: newFriends,
                            friends_count: newFriends.length,
                        };
                    } else if (user.id === action.payload.following.id) {
                        const isFriend = user.followers.some(follower => follower.id === action.payload.follower.id);
                        const newFollowing = [
                            ...user.following,
                            {
                                id: action.payload.follower.id,
                                name: action.payload.follower.name,
                                avatar: action.payload.follower.avatar,
                                country: action.payload.follower.country
                            }
                        ];
                        const newFriends = isFriend ? [
                            ...user.friends,
                            {
                                id: action.payload.follower.id,
                                name: action.payload.follower.name,
                                avatar: action.payload.follower.avatar,
                                country: action.payload.follower.country
                            }
                        ] : user.friends;
                        return {
                            ...user,
                            following: newFollowing,
                            following_count: newFollowing.length,
                            friends: newFriends,
                            friends_count: newFriends.length,
                        };
                    }
                    return user;
                })
            };

        case 'UNFOLLOW_USER':
            return {
                ...state,
                follows: state.follows.map(user => {
                    if (user.id === action.payload.follower_id) {
                        const updatedFollowers = user.followers.filter(follower => follower.id !== action.payload.following_id);
                        const updatedFriends = user.friends.filter(friend => friend.id !== action.payload.following_id);
                        return {
                            ...user,
                            followers: updatedFollowers,
                            followers_count: updatedFollowers.length,
                            friends: updatedFriends,
                            friends_count: updatedFriends.length
                        };
                    } else if (user.id === action.payload.following_id) {
                        const updatedFollowing = user.following.filter(following => following.id !== action.payload.follower_id);
                        const updatedFriends = user.friends.filter(friend => friend.id !== action.payload.follower_id);
                        return {
                            ...user,
                            following: updatedFollowing,
                            following_count: updatedFollowing.length,
                            friends: updatedFriends,
                            friends_count: updatedFriends.length
                        };
                    }
                    return user;
                })
            };

        case 'UPDATE_USER':
            return {
                ...state,
                follows: state.follows.map(user =>
                    user.id === action.payload.id ? { ...user, ...action.payload } : user
                )
            };

        default:
            return state;
    }
};