import React, {useCallback, useContext} from "react";
import {RoomContext} from "../../context/RoomContext";
import {RouletteList} from "./RouletteList";
import {RouletteLoading} from "./RouletteLoading";
import {RouletteSpin} from "./RouletteSpin";
import {RouletteUsers} from "./RouletteUsers";
import {RouletteCustom} from "./RouletteCustom";

export const Roulette = () => {
    const {
        rouletteMap,
        rouletteType,
        stepRoulette,
        setStepRoulette,
        localShowRoulette,
        setLocalShowRoulette
    } = useContext(RoomContext);

    const closeRoulette = useCallback(() => {
        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            stepRoulette: 'list',
            isActiveRoulette: false,
            rouletteType: null,
            items: [],
            isSpinning: false
        });
    }, [rouletteMap, setLocalShowRoulette]);

    const renderRouletteContent = () => {
        if (stepRoulette === 'list') {
            return <RouletteList/>
        } else if (stepRoulette === 'loading') {
            return <RouletteLoading/>
        } else if (stepRoulette === 'spin') {
            return <RouletteSpin/>
        } else if (stepRoulette === 'users') {
            return <RouletteUsers/>
        } else if (stepRoulette === 'custom') {
            return <RouletteCustom/>
        }
    }

    return (
        <React.Fragment>
            <div className={`wrapper-quiz-active ${localShowRoulette && 'show'}`}>
                <div className="header-quiz d-flex justify-content-between">
                    <div className="m-2">
                        <button type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => setLocalShowRoulette(false)}>
                            Hide
                        </button>
                    </div>

                    <div>
                        <p className="quiz-name text-white" onClick={() => setStepRoulette('list')}>{rouletteType ? rouletteType : 'Roulette'}</p>
                    </div>

                    <span className="m-2">
                        <button type="button" className="btn btn-danger" onClick={closeRoulette}>Close</button>
                    </span>
                </div>
                <div className="content-quiz overflow-auto border-bottom">
                    {renderRouletteContent()}
                </div>
            </div>
        </React.Fragment>
    );
}