import {ButtonOpenModalDebug} from "./buttons/ButtonOpenModalDebug";
import {PersonPlus} from "react-bootstrap-icons";
import {useSelector} from "react-redux";
import {ModalCreateRoom} from "./modals/ModalCreateRoom";
import {ButtonPurple} from "./buttons/ButtonPurple";

export const Filter = () => {
    const user = useSelector(state => state.user);
    const bsTargetCreateRoom = () => {
      return (user.id) ? '#createRoomModal' : '#ModalForUserNotAuth';
    }
    return (
        <header className="row my-3">
            <div className="col-6 col-md-3 col-sm-6 my-1">
                <ButtonPurple
                    text="Create Room"
                    attributes={[
                        {key: 'data-bs-toggle', value: 'modal'},
                        {key: 'data-bs-target', value: bsTargetCreateRoom()},
                    ]}
                    className='not-copy-text'
                />
            </div>
            <div className="col-6 col-md-3 col-sm-6 my-1">
                <ButtonOpenModalDebug />
            </div>
            <div className="col-6 col-md-3 col-sm-6 my-1">
                <ButtonPurple
                    icon={PersonPlus}
                    iconSize={24}
                    text="About"
                    attributes={[
                        {key: 'data-bs-toggle', value: 'modal'},
                        {key: 'data-bs-target', value: '#ModalInfoApp'},
                    ]}
                />
            </div>
            <div className="col-6 col-md-3 col-sm-6 my-1">
                <ButtonPurple
                    icon={PersonPlus}
                    iconSize={24}
                    text="Assistant"
                    attributes={[
                        {key: 'data-bs-toggle', value: 'modal'},
                        {key: 'data-bs-target', value: '#ModalInfoApp'},
                    ]}
                />
            </div>

            <ModalCreateRoom />
        </header>
    );
}