const initialState = {
    id: null,
    name: "",
    avatar: "",
    country_id: null,
    code: null,
    country: null,
    email: "",
    subscription_type: "",
    subscription_expiry: null,
    created_at: ""
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_USER':
            return action.payload;
        case 'LOGOUT_USER':
            return initialState;
        case 'UPDATE_COUNTRY':
            return {
                ...state,
                country_id: action.payload.id,
                code: action.payload.code,
                country: action.payload.country
            };
        default:
            return state;
    }
};