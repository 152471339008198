export const loginUser = (user) => ({
    type: 'LOGIN_USER',
    payload: user,
});

export const logoutUser = () => ({
    type: 'LOGOUT_USER',
});

export const updateUserCountry = (country) => ({
    type: 'UPDATE_COUNTRY',
    payload: country
});