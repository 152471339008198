import {format, isSameDay, isThisYear} from "date-fns";

// Текущая дата и время
export const getCurrentTimestamp = () => {
    const now = new Date();
    const datePart = format(now, 'yyyy-MM-dd');
    const timePart = format(now, 'HH:mm:ss.SSS');
    const microseconds = '000000'; // Date object only goes down to milliseconds

    return `${datePart} ${timePart}${microseconds}`;
}


// Например: '09 July' или 09 July 2024
export const formatDateDMOrDMY = (dateString)  => {
    const date = new Date(dateString);
    if (isThisYear(date)) {
        return format(date, 'dd MMMM'); // Например: '09 июня'
    } else {
        return format(date, 'dd MMMM yyyy'); // Например: '09 июня 2024'
    }
}

//
export const formatDateHM = (dateString)  => {
    const date = new Date(dateString);
    return format(date, 'H:mm');
}

// Две даты являются одним днем или разными.
export const isDifferentDay = (date, arrayDate, index) => {
    if (index === 0) return true;
    const currentDate = new Date(date);
    const previousDate = new Date(arrayDate[index - 1].created_at);
    return !isSameDay(currentDate, previousDate);
}