/**
 * Добавить размер для item для roulette
 */
export const updatedItem = (item) => {

    const length = item.option.length;
    let numberSize = 1;

    if (length <= 14) numberSize = 20;
    else if (length <= 20) numberSize = 16;
    else if (length <= 30) numberSize = 12;
    else if (length <= 39) numberSize = 9;
    else if (length <= 53) numberSize = 7;
    else if (length <= 75) numberSize = 5;
    else if (length <= 94) numberSize = 4;
    else if (length <= 130) numberSize = 3;
    else if (length <= 192) numberSize = 2;

    return {
        ...item,
        style: { fontSize: numberSize }
    };
};