import {createSelector} from "reselect";

const selectRoomsData = state => state.rooms.data;
const selectRoomsState = state => state.rooms;

// Селектор для поиска комнаты по id
export const selectRoomById = roomId => createSelector(
    [selectRoomsData],
    rooms => rooms.find(room => room.room_id === roomId)
);

export const selectRoomLoading = createSelector(
    [selectRoomsState],
    rooms => rooms.loading
);