import http from "./index";


// получить все комнаты
export const getRooms = async () => {
    try {
        const res = await http.get('rooms');
        return res.data;
    } catch (err) {
        throw err;
    }
}


// получить комнату по room_id
export const getRoomById = async (room_id) => {
    try {
        const res = await http.get('rooms/' + room_id);
        return res.data;
    } catch (err) {
        throw err;
    }
}


// сохранить новую комнату
export const saveRoom = async (data) => {
    try {
        return await http.post('rooms', data);
    } catch (err) {
        throw err;
    }
}

// обновить новую комнату
export const updateRoomById = async (data) => {
    try {
        return await http.put('rooms', data);
    } catch (err) {
        throw err;
    }
}

// обновить новую комнату
export const updateAccessRoomById = async (data) => {
    try {
        return await http.put('rooms/access', data);
    } catch (err) {
        throw err;
    }
}

// удалить комнату по room_id
export const deleteRoomById = async (room_id) => {
    try {
        return await http.delete('rooms/', {
            data: {
                room_id: room_id
            }
        });
    } catch (err) {
        throw err;
    }
}
