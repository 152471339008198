import {SocialPeopleTab} from "./SocialPeopleTab";
import {SocialMessagesTab} from "./SocialMessagesTab";
import {ChatLeftDots, PeopleFill} from "react-bootstrap-icons";
import React from "react";

export const SocialTab = () => {
    return (
        <div>
            <ul className="nav nav-pills border-bottom d-flex justify-content-between align-items-center" id="pills-tab" role="tablist">
                <div className="d-flex">
                    <li className="nav-item" role="presentation">
                        <p className="social-nav-link pointer active" id="social-people-tab" data-bs-toggle="pill"
                           data-bs-target="#pills-social-people" role="tab" aria-controls="pills-social-people"
                           aria-selected="true"><PeopleFill />  People
                        </p>
                    </li>
                    <li className="nav-item" role="presentation">
                        <p className="social-nav-link pointer" id="social-messages-tab" data-bs-toggle="pill"
                           data-bs-target="#pills-social-messages" role="tab" aria-controls="pills-social-messages"
                           aria-selected="false"><ChatLeftDots />  Messages
                        </p>
                    </li>
                </div>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </ul>

            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-social-people" role="tabpanel"
                     aria-labelledby="social-people-tab" tabIndex="0">
                    <SocialPeopleTab />
                </div>
                <div className="tab-pane fade" id="pills-social-messages" role="tabpanel" aria-labelledby="social-messages-tab"
                     tabIndex="0">
                    <SocialMessagesTab />
                </div>
            </div>
        </div>
    );
}