import {createStore, combineReducers} from 'redux';
import {userReducer} from './reducers/user';
import {chatReducer} from './reducers/chat';
import {privateChatReducer} from './reducers/privateChat';
import {roomReducer} from "./reducers/room";
import {followsReducer} from "./reducers/follow";
import {peerReducer} from "./reducers/peer";
import {oneRoomReducer} from "./reducers/oneRoom";
import {levelReducer} from "./reducers/level";
import {languageReducer} from "./reducers/language";
import {countryReducer} from "./reducers/country";
import {quizTypeReducer} from "./reducers/quizType";
import {rouletteTypeReducer} from "./reducers/rouletteType";

const rootReducer = combineReducers({
    user: userReducer,
    chat: chatReducer,
    privateChat: privateChatReducer,
    rooms: roomReducer,
    oneRoom: oneRoomReducer,
    levels: levelReducer,
    languages: languageReducer,
    countries: countryReducer,
    follows: followsReducer,
    quizTypes: quizTypeReducer,
    rouletteTypes: rouletteTypeReducer,
    peers: peerReducer,
});

const store = createStore(rootReducer);

export default store;