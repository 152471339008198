import React, {useCallback, useContext, useState} from "react";
import {RoomContext} from "../../context/RoomContext";
import {Wheel} from "react-custom-roulette";
import {useSelector} from "react-redux";
import {updatedItem} from "../../utils/rouletteHelpers";
import {ButtonPurpleBorder} from "../buttons/ButtonPurleBorder";

export const RouletteCustom = () => {
    const {rouletteMap, isSpinning, rouletteResult, items} = useContext(RoomContext);
    const user = useSelector(state => state.user);

    const [selectedOption, setSelectedOption] = useState('')
    const [message, setMessage] = useState('');

    const handleSpinClick = () => {
        if (!isSpinning) {
            const newPrizeNumber = Math.floor(Math.random() * items.length);

            rouletteMap.current.set('roulette', {
                ...rouletteMap.current.get('roulette'),
                rouletteResult: newPrizeNumber,
                isSpinning: true
            });
        }
    }

    const handleStopSpinning = () => {
        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            isSpinning: false
        });
        setSelectedOption(items[rouletteResult].option)
    }

    const addItemToRoulette = (e) => {
        if (e) e.preventDefault();
        if (!message.trim()) return;
        let newItems = [...items];
        const newItem = updatedItem({ option: message })
        if (newItems.length === 2 && newItems[0].option === "Roulette" && newItems[1].option === "Custom") {
            newItems = [newItem];
        } else {
            newItems = [...newItems, newItem];
        }

        setMessage('');

        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            items: newItems
        });
    };

    const removeItemFromRoulette = (index) => {
        let newItems = [
            ...items.slice(0, index),
            ...items.slice(index + 1)
        ];

        if (newItems.length === 0) {
            newItems = [{ option: "Roulette" }, { option: "Custom" }];
        }

        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            items: newItems.length === 0 ? [{ option: "Roulette" }, { option: "Custom" }] : newItems
        });
    };

    const changeRoulette = useCallback(() => {
        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            stepRoulette: 'list',
            rouletteType: null,
            isSpinning: false,
            items: []
        });
    }, [rouletteMap]);

    return (
        <div>
            <div className="text-center m-2">
                <button type="button" className="btn btn-secondary width-250px" onClick={changeRoulette}>Change roulette</button>
            </div>
            <div className="d-flex justify-content-center">
                <Wheel
                    mustStartSpinning={isSpinning}
                    prizeNumber={rouletteResult}
                    data={items}
                    onStopSpinning={handleStopSpinning}
                    backgroundColors={['#00c75d', '#ff602e']}
                    textColors={['#ffffff']}
                    textDistance={55}
                    spinDuration={0.5}
                />
            </div>
            <div className="text-center m-2">
                <ButtonPurpleBorder
                    onClick={handleSpinClick}
                    text='SPIN'
                    className='width-250px'
                />
            </div>
            <p className="text-white mx-2">Result: {selectedOption && selectedOption}</p>
            <div className="container chat-block-form">
                <form className="d-flex align-items-center py-1" onSubmit={addItemToRoulette}>
                    <input
                        className="form-control bg-dark-input text-white"
                        type="text"
                        placeholder="Type a message"
                        aria-label="default input example"
                        onChange={e => setMessage(e.target.value)}
                        value={message}/>
                    <button type="submit" className={`text-white btn-send-item-roulette ${message.trim() && 'ready'}`}>
                        Add item
                    </button>
                </form>
                <p className="text-white text-center">list of items</p>
                {items !== 2 && items[0]?.option !== "Roulette" && items[1]?.option !== "Custom" &&
                    items.map((item, index) => (
                        <div className="d-flex justify-content-between align-items-center border-bottom my-1">
                            <div     className="text-white">{index + 1}. {item.option}</div>
                            <button type="button" className="btn btn-danger my-1" onClick={() => removeItemFromRoulette(index)}>Remove</button>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}