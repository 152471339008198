import {
    CardChecklistIconBase64,
    InfoCircleIconBase64,
    PencilSquareIconBase64, PlusCircleIconBase64,
    ProfileSquareIconBase64,
    TrashIconBase64,
    WechatIconBase64
} from "./iconBase64";

export const customQuizzesButton = {
    icon: CardChecklistIconBase64,
    id: 'custom-quiz',
    text: 'Games'
}

export const customProfileButton = {
    icon: ProfileSquareIconBase64,
    id: 'custom-profile',
    text: 'Profile'
}

export const customSocialButton = {
    icon: WechatIconBase64,
    id: 'custom-social',
    text: 'Social'
}

export const customInfoRoomButton = {
    icon: InfoCircleIconBase64,
    id: 'custom-info-room',
    text: 'Info room',
}

export const customInviteRoomButton = {
    icon: PlusCircleIconBase64,
    id: 'custom-invite-room',
    text: 'Invite',
}

export const customEditRoomButton = {
    icon: PencilSquareIconBase64,
    id: 'custom-edit-room',
    text: 'Edit room',
}

export const customDeleteRoomButton = {
    icon: TrashIconBase64,
    id: 'custom-delete-room',
    text: 'Delete room',
    backgroundColor: '#cb2233',
}