import {ArrowRepeat, CardChecklist} from "react-bootstrap-icons";
import React, {useContext} from "react";
import {RoomContext} from "../context/RoomContext";

export const RoomWrapperButtonsGames = () => {
    const {isActiveQuiz, setLocalShowQuiz, isActiveRoulette, setLocalShowRoulette} = useContext(RoomContext);

    return (
        <div className="wrapper-game-buttons-list">
            {isActiveQuiz &&
                <div className="games-button pointer d-flex flex-column p-1"
                     onClick={() => setLocalShowQuiz(true)}>
                    <div className="text-center">
                        <CardChecklist size={26} />
                    </div>
                    <p className="text-center">Quiz</p>
                </div>
            }

            {isActiveRoulette &&
                <div className="games-button pointer d-flex flex-column p-1"
                     onClick={() => setLocalShowRoulette(true)}>
                    <div className="text-center">
                        <ArrowRepeat size={26} />
                    </div>
                    <p className="text-center">Roulette</p>
                </div>
            }
        </div>
    );
}