import React, {useState} from "react";
import {debug} from "../../axios";

export const ModalDebug = () => {
    const [debugInfo, setDebugInfo] = useState({
        data: [],
        resCount: 0,
        errCount: 0,
    });

    const handleGetDebug = () => {
        const resCount = debug.filter(item => item.res).length;
        const errCount = debug.filter(item => item.err).length;
        setDebugInfo({ data: debug, resCount, errCount });
    };

    return (
        <div className='modal fade' id='ModalDebugApp' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-for-not-auth">
                <div className="modal-content">
                    <button onClick={handleGetDebug}>getDebug</button>
                    <hr/>
                    <p>res:  {debugInfo.resCount}</p>
                    <p>err: {debugInfo.errCount}</p>
                    <hr/>
                    {debugInfo.data.map((item, index) => (
                        item.res ? (
                            <p key={index} className="bg-info text-white p-1" style={{ wordWrap: 'break-word' }}>{JSON.stringify(item)}</p>
                        ) : (
                            <p key={index} className="bg-danger text-white p-1" style={{ wordWrap: 'break-word' }}>{JSON.stringify(item)}</p>
                        )
                    ))}
                </div>
            </div>
        </div>
    );
}