import React, {useState} from "react";
import {SocialChat} from "./SocialChat";
import {PopoverConfirmDeleteChat} from "../PopoverConfirmDeleteChat";
import {useSelector} from "react-redux";
import {FiletypeGif} from "react-bootstrap-icons";
import {truncateString} from "../../utils/htmlHelpers";

export const SocialMessagesTab = () => {
    const user = useSelector(state => state.user);
    const privateChat = useSelector(state => state.privateChat);
    const [openChats, setOpenChats] = useState({});
    const [searchTerm, setSearchTerm] = useState('');

    const toggleOpenChat = (chatId) => {
        setOpenChats(prevState => ({
            ...prevState,
            [chatId]: !prevState[chatId]
        }));
    };

    // Фильтрация чатов по имени и сортировка по времени последнего сообщения
    const filteredAndSortedChats = privateChat.data
        ?.filter(chat =>
            chat.user.name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            const aMessages = a.messages;
            const bMessages = b.messages;

            const aUpdatedAt = new Date(aMessages[aMessages.length - 1].updated_at).getTime();
            const bUpdatedAt = new Date(bMessages[bMessages.length - 1].updated_at).getTime();

            return bUpdatedAt - aUpdatedAt; // Сортировка в порядке убывания
        });


    const renderMessage = (message) => {
      switch (message.type) {
          case 'text':
              return truncateString(message.message, 15);
          case 'gif':
              return <FiletypeGif />
          default:
              return truncateString(message.message, 15);
      }
    }


    return (
        <div className="">
            <form className="d-flex" role="search" onSubmit={(e) => e.preventDefault()}>
                <input
                    className="form-control me-2 m-2 bg-dark-input text-white"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </form>
            <div className="wrapper-people-list-messages">
                {filteredAndSortedChats.map((chat) => (
                    <div key={chat.user.id}>
                        <div className="d-flex align-items-center social-list-people">
                            <div className="me-auto d-flex align-items-center pointer"
                                 onClick={() => toggleOpenChat(chat.user.id)}>
                                <img
                                    src={chat.user.avatar}
                                    alt={`Avatar`}
                                    className="img-fluid rounded-circle"
                                />
                                <div>
                                    <p className={`mb-0 ms-2 social-list-people-name
                                    ${(chat.messages[chat.messages.length - 1].sender_id !== user.id && !chat.messages[chat.messages.length - 1].is_read) ? ' brand-color' : ''}`}>{chat.user.name}</p>
                                    <p className={`mb-0 ms-2 social-list-people-message`}>
                                        {(chat.messages[chat.messages.length - 1].sender_id === user.id) && 'You:'} {renderMessage(chat.messages[chat.messages.length - 1])}
                                    </p>
                                </div>

                            </div>
                            <div className="">
                                <PopoverConfirmDeleteChat key={chat.user.id} id={chat.user.id} name={chat.user.name}/>
                            </div>
                        </div>
                        {openChats[chat.user.id] &&
                            <SocialChat index={chat.user.id}
                                        isOpen={openChats[chat.user.id]}
                                        toggleOpen={() => toggleOpenChat(chat.user.id)}
                                        chat={chat?.messages}
                                        userInfo={chat.user}
                            />
                        }
                    </div>
                ))}
            </div>
        </div>
    );
}