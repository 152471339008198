import React, {useState} from 'react';
import Card from './Card';
import {useSelector} from "react-redux";
import {selectRoomLoading} from "../redux/selectors/roomsSelectors";
import {FilterRoom} from "./FilterRoom";

export const ListRooms = () => {
    const rooms = useSelector(state => state.rooms);
    const roomLoading = useSelector(selectRoomLoading);

    const [selectedLanguage, setSelectedLanguage] = useState("Any");
    const [selectedLevel, setSelectedLevel] = useState("Any");

    // Фильтрация комнат по языку и уровню
    const filteredRooms = rooms.data?.filter(room => {
        const languageMatches = selectedLanguage === "Any" || room.language === selectedLanguage;
        const levelMatches = selectedLevel === "Any" || room.level === selectedLevel;
        return languageMatches && levelMatches;
    });

    return (
        <div>
            <FilterRoom
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                selectedLevel={selectedLevel}
                setSelectedLevel={setSelectedLevel}
            />
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                {roomLoading ? (
                    <div className='d-flex justify-content-center align-items-center loading-spinner'>
                        <div className="spinner-border text-dark" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    filteredRooms?.map((room, index) => (
                        <Card key={index} room={room} />
                    ))
                )}
            </div>
        </div>
    );
};
