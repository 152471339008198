import React from "react";
import {BoxArrowInRight, XLg} from "react-bootstrap-icons";

export const ModalInfo = () => {

    return (
        <div className='modal fade' id='ModalInfoApp' tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-for-not-auth">
                <div className="modal-content">
                    <div className="modal-header justify-content-center">
                        <BoxArrowInRight size={60} color={"white"} />
                        <span className="close pointer" data-dismiss="modal"
                              aria-hidden="true" data-bs-dismiss="modal" aria-label="Close"><XLg size={24}/></span>
                    </div>
                    <div className="modal-body text-center">
                        <p className="modal-question">Info</p>
                        <p>Description</p>
                        <button
                            className="width-150px btn-dark-default btn-be-purple"
                            data-dismiss="modal">
                            <span>Speak-Stream</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}