export const CardChecklistIconBase64 = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgY29sb3I9IiNGRkZGRkYiIGZpbGw9ImN1cnJlbnRDb2xvciIgY2xhc3M9ImJpIGJpLWNhcmQtY2hlY2tsaXN0IiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik0xNC41IDNhLjUuNSAwIDAgMSAuNS41djlhLjUuNSAwIDAgMS0uNS41aC0xM2EuNS41IDAgMCAxLS41LS41di05YS41LjUgMCAwIDEgLjUtLjV6bS0xMy0xQTEuNSAxLjUgMCAwIDAgMCAzLjV2OUExLjUgMS41IDAgMCAwIDEuNSAxNGgxM2ExLjUgMS41IDAgMCAwIDEuNS0xLjV2LTlBMS41IDEuNSAwIDAgMCAxNC41IDJ6Ii8+CiAgPHBhdGggZD0iTTcgNS41YS41LjUgMCAwIDEgLjUtLjVoNWEuNS41IDAgMCAxIDAgMWgtNWEuNS41IDAgMCAxLS41LS41bS0xLjQ5Ni0uODU0YS41LjUgMCAwIDEgMCAuNzA4bC0xLjUgMS41YS41LjUgMCAwIDEtLjcwOCAwbC0uNS0uNWEuNS41IDAgMSAxIC43MDgtLjcwOGwuMTQ2LjE0NyAxLjE0Ni0xLjE0N2EuNS41IDAgMCAxIC43MDggME03IDkuNWEuNS41IDAgMCAxIC41LS41aDVhLjUuNSAwIDAgMSAwIDFoLTVhLjUuNSAwIDAgMS0uNS0uNW0tMS40OTYtLjg1NGEuNS41IDAgMCAxIDAgLjcwOGwtMS41IDEuNWEuNS41IDAgMCAxLS43MDggMGwtLjUtLjVhLjUuNSAwIDAgMSAuNzA4LS43MDhsLjE0Ni4xNDcgMS4xNDYtMS4xNDdhLjUuNSAwIDAgMSAuNzA4IDAiLz4KPC9zdmc+';

export const ProfileSquareIconBase64 = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgY29sb3I9IiNGRkZGRkYiIGZpbGw9ImN1cnJlbnRDb2xvciIKICAgICAgICAgICAgICAgICBjbGFzc05hbWU9ImJpIGJpLXBlcnNvbi1zcXVhcmUiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTEgNmEzIDMgMCAxIDEtNiAwIDMgMyAwIDAgMSA2IDAiLz4KICAgICAgICAgICAgICAgIDxwYXRoCiAgICAgICAgICAgICAgICAgICAgZD0iTTIgMGEyIDIgMCAwIDAtMiAydjEyYTIgMiAwIDAgMCAyIDJoMTJhMiAyIDAgMCAwIDItMlYyYTIgMiAwIDAgMC0yLTJ6bTEyIDFhMSAxIDAgMCAxIDEgMXYxMmExIDEgMCAwIDEtMSAxdi0xYzAtMS0xLTQtNi00cy02IDMtNiA0djFhMSAxIDAgMCAxLTEtMVYyYTEgMSAwIDAgMSAxLTF6Ii8+CiAgICAgICAgICAgIDwvc3ZnPg==';

export const WechatIconBase64 = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgY29sb3I9IiNGRkZGRkYiIGZpbGw9ImN1cnJlbnRDb2xvciIgY2xhc3M9ImJpIGJpLXdlY2hhdCIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNMTEuMTc2IDE0LjQyOWMtMi42NjUgMC00LjgyNi0xLjgtNC44MjYtNC4wMTggMC0yLjIyIDIuMTU5LTQuMDIgNC44MjQtNC4wMlMxNiA4LjE5MSAxNiAxMC40MTFjMCAxLjIxLS42NSAyLjMwMS0xLjY2NiAzLjAzNmEuMzIuMzIgMCAwIDAtLjEyLjM2NmwuMjE4LjgxYS42LjYgMCAwIDEgLjAyOS4xMTcuMTY2LjE2NiAwIDAgMS0uMTYyLjE2Mi4yLjIgMCAwIDEtLjA5Mi0uMDNsLTEuMDU3LS42MWEuNS41IDAgMCAwLS4yNTYtLjA3NC41LjUgMCAwIDAtLjE0Mi4wMjEgNS43IDUuNyAwIDAgMS0xLjU3Ni4yMk05LjA2NCA5LjU0MmEuNjQ3LjY0NyAwIDEgMCAuNTU3LTEgLjY0NS42NDUgMCAwIDAtLjY0Ni42NDcuNi42IDAgMCAwIC4wOS4zNTNabTMuMjMyLjAwMWEuNjQ2LjY0NiAwIDEgMCAuNTQ2LTEgLjY0NS42NDUgMCAwIDAtLjY0NC42NDQuNjMuNjMgMCAwIDAgLjA5OC4zNTYiLz4KICA8cGF0aCBkPSJNMCA2LjgyNmMwIDEuNDU1Ljc4MSAyLjc2NSAyLjAwMSAzLjY1NmEuMzg1LjM4NSAwIDAgMSAuMTQzLjQzOWwtLjE2MS42LS4xLjM3M2EuNS41IDAgMCAwLS4wMzIuMTQuMTkuMTkgMCAwIDAgLjE5My4xOTNxLjA2IDAgLjExMS0uMDI5bDEuMjY4LS43MzNhLjYuNiAwIDAgMSAuMzA4LS4wODhxLjA4OCAwIC4xNzEuMDI1YTYuOCA2LjggMCAwIDAgMS42MjUuMjYgNC41IDQuNSAwIDAgMS0uMTc3LTEuMjUxYzAtMi45MzYgMi43ODUtNS4wMiA1LjgyNC01LjAybC4xNS4wMDJDMTAuNTg3IDMuNDI5IDguMzkyIDIgNS43OTYgMiAyLjU5NiAyIDAgNC4xNiAwIDYuODI2bTQuNjMyLTEuNTU1YS43Ny43NyAwIDEgMS0xLjU0IDAgLjc3Ljc3IDAgMCAxIDEuNTQgMG0zLjg3NSAwYS43Ny43NyAwIDEgMS0xLjU0IDAgLjc3Ljc3IDAgMCAxIDEuNTQgMCIvPgo8L3N2Zz4=';

export const InfoCircleIconBase64 = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgY29sb3I9IiNGRkZGRkYiIGZpbGw9ImN1cnJlbnRDb2xvciIgY2xhc3M9ImJpIGJpLWluZm8tY2lyY2xlIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik04IDE1QTcgNyAwIDEgMSA4IDFhNyA3IDAgMCAxIDAgMTRtMCAxQTggOCAwIDEgMCA4IDBhOCA4IDAgMCAwIDAgMTYiLz4KICA8cGF0aCBkPSJtOC45MyA2LjU4OC0yLjI5LjI4Ny0uMDgyLjM4LjQ1LjA4M2MuMjk0LjA3LjM1Mi4xNzYuMjg4LjQ2OWwtLjczOCAzLjQ2OGMtLjE5NC44OTcuMTA1IDEuMzE5LjgwOCAxLjMxOS41NDUgMCAxLjE3OC0uMjUyIDEuNDY1LS41OThsLjA4OC0uNDE2Yy0uMi4xNzYtLjQ5Mi4yNDYtLjY4Ni4yNDYtLjI3NSAwLS4zNzUtLjE5My0uMzA0LS41MzN6TTkgNC41YTEgMSAwIDEgMS0yIDAgMSAxIDAgMCAxIDIgMCIvPgo8L3N2Zz4=';

export const PlusCircleIconBase64 = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgY29sb3I9IiNGRkZGRkYiIGZpbGw9ImN1cnJlbnRDb2xvciIgY2xhc3M9ImJpIGJpLXBsdXMtY2lyY2xlIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik04IDE1QTcgNyAwIDEgMSA4IDFhNyA3IDAgMCAxIDAgMTRtMCAxQTggOCAwIDEgMCA4IDBhOCA4IDAgMCAwIDAgMTYiLz4KICA8cGF0aCBkPSJNOCA0YS41LjUgMCAwIDEgLjUuNXYzaDNhLjUuNSAwIDAgMSAwIDFoLTN2M2EuNS41IDAgMCAxLTEgMHYtM2gtM2EuNS41IDAgMCAxIDAtMWgzdi0zQS41LjUgMCAwIDEgOCA0Ii8+Cjwvc3ZnPg==';

export const PencilSquareIconBase64 = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgY29sb3I9IiNGRkZGRkYiIGZpbGw9ImN1cnJlbnRDb2xvciIgY2xhc3M9ImJpIGJpLXBlbmNpbC1zcXVhcmUiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHBhdGggZD0iTTE1LjUwMiAxLjk0YS41LjUgMCAwIDEgMCAuNzA2TDE0LjQ1OSAzLjY5bC0yLTJMMTMuNTAyLjY0NmEuNS41IDAgMCAxIC43MDcgMGwxLjI5MyAxLjI5M3ptLTEuNzUgMi40NTYtMi0yTDQuOTM5IDkuMjFhLjUuNSAwIDAgMC0uMTIxLjE5NmwtLjgwNSAyLjQxNGEuMjUuMjUgMCAwIDAgLjMxNi4zMTZsMi40MTQtLjgwNWEuNS41IDAgMCAwIC4xOTYtLjEybDYuODEzLTYuODE0eiIvPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEgMTMuNUExLjUgMS41IDAgMCAwIDIuNSAxNWgxMWExLjUgMS41IDAgMCAwIDEuNS0xLjV2LTZhLjUuNSAwIDAgMC0xIDB2NmEuNS41IDAgMCAxLS41LjVoLTExYS41LjUgMCAwIDEtLjUtLjV2LTExYS41LjUgMCAwIDEgLjUtLjVIOWEuNS41IDAgMCAwIDAtMUgyLjVBMS41IDEuNSAwIDAgMCAxIDIuNXoiLz4KPC9zdmc+';

export const TrashIconBase64 = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgY29sb3I9IiNGRkZGRkYiIGZpbGw9ImN1cnJlbnRDb2xvciIgY2xhc3M9ImJpIGJpLXRyYXNoIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Ik01LjUgNS41QS41LjUgMCAwIDEgNiA2djZhLjUuNSAwIDAgMS0xIDBWNmEuNS41IDAgMCAxIC41LS41bTIuNSAwYS41LjUgMCAwIDEgLjUuNXY2YS41LjUgMCAwIDEtMSAwVjZhLjUuNSAwIDAgMSAuNS0uNW0zIC41YS41LjUgMCAwIDAtMSAwdjZhLjUuNSAwIDAgMCAxIDB6Ii8+CiAgPHBhdGggZD0iTTE0LjUgM2ExIDEgMCAwIDEtMSAxSDEzdjlhMiAyIDAgMCAxLTIgMkg1YTIgMiAwIDAgMS0yLTJWNGgtLjVhMSAxIDAgMCAxLTEtMVYyYTEgMSAwIDAgMSAxLTFINmExIDEgMCAwIDEgMS0xaDJhMSAxIDAgMCAxIDEgMWgzLjVhMSAxIDAgMCAxIDEgMXpNNC4xMTggNCA0IDQuMDU5VjEzYTEgMSAwIDAgMCAxIDFoNmExIDEgMCAwIDAgMS0xVjQuMDU5TDExLjg4MiA0ek0yLjUgM2gxMVYyaC0xMXoiLz4KPC9zdmc+';
