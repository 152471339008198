import {useContext, useEffect} from "react";
import {
    addConnectedUser,
    addRoom,
    removeConnectedUser,
    removeRoom,
    updateRoomById,
    updateRoomAccess
} from "../redux/actions/room";
import {useDispatch} from "react-redux";
import {WSContext} from "../context/WSContext";

/**
 * Обработчик ws событий для home page
 */
export const useWebSocketHome = () => {
    const { ws } = useContext(WSContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!ws) return;

        // Обработчик для созданной комнаты
        ws.on('home-room-created', ({room}) => {
            console.log('new room:', room);
            dispatch(addRoom(room));
        });

        // Обработчик для обновления комнаты
        ws.on('home-room-updated', ({data, type}) => {
            console.log('update room:', data, type);
            if (type === 'general') {
                dispatch(updateRoomById(data));
            } else if (type === 'access') {
                dispatch(updateRoomAccess(data));
            }
        });

        // Обработчик когда пользователь присоединился к комнате
        ws.on('home-user-joined-to-room', ({room_id, user}) => {
            console.log('home-user-joined', user);
            dispatch(addConnectedUser(room_id, user))
        });

        // Обработчик когда пользователь покинул комнату
        ws.on('home-user-left-room', ({room_id, user_id}) => {
            console.log('home-left-room', user_id);
            dispatch(removeConnectedUser(room_id, user_id));
        });

        // Обработчик для удаленной комнаты
        ws.on('home-deleted-room', ({room_id}) => {
            console.log("home room deleted", room_id);
            dispatch(removeRoom(room_id));
        });
        return () => {
            if (ws) {
                ws.off('home-room-created');
                ws.off('home-room-updated');
                ws.off('home-user-joined-to-room');
                ws.off('home-user-left-room');
                ws.off('home-deleted-room');
            }
        };
    }, [ws]);
}