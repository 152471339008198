import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {WSContext} from "../context/WSContext";
import {useDispatch, useSelector} from "react-redux";
import {useWebSocketRoom} from "../hooks/useWebSocketRoom";
import {getRoomById} from "../axios/roomRequests";
import {addConnectedUserToOneRoom, removeOneRoom, setOneRoom} from "../redux/actions/oneRoom";
import {JitsiMeeting} from "@jitsi/react-sdk";
import {saveUserInRoom} from "../axios/roomUsersRequests";
import {
    customDeleteRoomButton,
    customEditRoomButton, customInfoRoomButton, customInviteRoomButton,
    customProfileButton, customQuizzesButton,
    customSocialButton
} from "../utils/customButtonsJitsi";
import {useCloseModal} from "../hooks/useCloseModal";
import {ModalInfoRoom} from "../components/modals/ModalInfoRoom";
import {ModalEditRoom} from "../components/modals/ModalEditRoom";
import {ModalInviteUser} from "../components/modals/ModalInviteUser";
import {ModalDeleteRoom} from "../components/modals/ModalDeleteRoom";
import {isInMobileStandaloneMode} from "../utils/deviceHelpers";
import {ModalGames} from "../components/modals/ModalGames";
import {RoomGames} from "../components/RoomGames";

export const Room = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const quizRef = useRef(null);
    const profileRef = useRef(null);
    const socialRef = useRef(null);
    const infoRoomRef = useRef(null);
    const inviteRoomRef = useRef(null);
    const editRoomRef = useRef(null);
    const deleteRoomRef = useRef(null);
    const {ws} = useContext(WSContext);
    const user = useSelector(state => state.user);
    const room = useSelector(state => state.oneRoom);
    const dispatch = useDispatch();
    const [jwtRoom, setJwtRoom] = useState(null);

    const [customToolbarButtonList, setCustomToolbarButtonList] = useState([
        customQuizzesButton, customProfileButton, customSocialButton, customInfoRoomButton
    ]);
    const toolbarButtonsList = [
        'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
        'fodeviceselection', 'hangup', 'chat', 'recording', 'livestreaming',
        'etherpad', 'sharedvideo', 'settings', 'videoquality', 'filmstrip',
        'shortcuts', 'tileview', 'videobackgroundblur', 'help', 'mute-everyone',
        'whiteboard', 'reactions', 'virtualBackgrounds'
    ];

    useEffect(() => {
        if (!id || !user.id) return;
        const setRoom = async () => {
            try {
                const fetchRoom = await getRoomById(id);
                if (!fetchRoom) {
                    navigate('/');
                }
                await saveUserInRoom({
                    room_id: id, user_id: user.id,
                })
                setJwtRoom(fetchRoom.jwt_room);
                dispatch(setOneRoom(fetchRoom.room));
                dispatch(addConnectedUserToOneRoom(user))
                if (fetchRoom.room.owner.id === user.id) {
                    setCustomToolbarButtonList(prevButtons => [
                        ...prevButtons,
                        customInviteRoomButton, customEditRoomButton, customDeleteRoomButton
                    ]);
                }
                ws.emit("join-room", {room_id: id, user_id: user.id, new_user: user});
                ws.emit("home-user-join", {room_id: id, user_id: user.id, user: user});
            } catch (e) {
                console.log(e);
            }
        }
        setRoom();
    }, [id, user.id]);

    useEffect(() => {
        if (!room.room_id) return;
        const isBan = room.black_list.includes(user.id);
        //todo full room
        //const isFull = room.quantity !== 0 && room.connected_users_count > room.quantity;
        if (isBan) {
            navigate('/');
        }
    }, [id, navigate, room.room_id]);

    useWebSocketRoom();
    useCloseModal();

    useEffect(() => {
        if (!room.room_id) return;

        return () => {
            dispatch(removeOneRoom());
            ws.emit("leave-room", {room_id: room.room_id, user_id: user.id});
            ws.emit("home-user-leave-room", {room_id: room.room_id, user_id: user.id});
        }
    }, [room.room_id]);

    const handlerOnApiReady = (externalApi) => {

        // Событие при подключении нового пользователя
        externalApi.addListener('participantJoined', (participant) => {
            // console.log('Участник присоединился:', participant, externalApi);
            // alert('Участник присоединился:', participant);
            // Ваша логика при подключении нового участника
        });

        // Событие при выходе пользователя
        externalApi.addListener('participantLeft', (participant) => {
            // console.log('Участник покинул конференцию:', participant, externalApi);
            // alert('Участник покинул конференцию:', participant);
            // // Ваша логика при выходе участника
        });

        externalApi.addListener('readyToClose', () => {
            // Редирект на главную страницу
            navigate('/');
        });

        externalApi.addListener('toolbarButtonClicked', (event) => {
            if (event.key === 'custom-quiz') {
                if (quizRef.current) {
                    quizRef.current.click();
                }
            } else if (event.key === 'custom-profile') {
                if (profileRef.current) {
                    profileRef.current.click();
                }
            } else if (event.key === 'custom-social') {
                if (socialRef.current) {
                    socialRef.current.click();
                }
            } else if (event.key === 'custom-info-room') {
                if (infoRoomRef.current) {
                    infoRoomRef.current.click();
                }
            } else if (event.key === 'custom-invite-room') {
                    if (inviteRoomRef.current) {
                        inviteRoomRef.current.click();
                    }
            } else if (event.key === 'custom-edit-room') {
                if (socialRef.current) {
                    editRoomRef.current.click();
                }
            } else if (event.key === 'custom-delete-room') {
                if (socialRef.current) {
                    deleteRoomRef.current.click();
                }
            }
        });

    }

    return (
        <div style={{ height:  isInMobileStandaloneMode() ? "98vh" : "100vh", display: "grid", flexDirection: "column", overflow: "hidden" }}>
            {(room.room_id && jwtRoom) &&
                <JitsiMeeting
                    domain = { "speak-stream-meet.com" }
                    roomName = {room.room_id}
                    jwt={jwtRoom}
                    configOverwrite = {{
                        startWithAudioMuted: true,
                        disableModeratorIndicator: true,
                        startScreenSharing: true,
                        enableEmailInStats: false,
                        disableProfile: true,
                        disableGeneral: true,
                        disableDeepLinking: true,
                        hiddenPremeetingButtons: ['invite'],
                        defaultLanguage: 'en',
                        customToolbarButtons: customToolbarButtonList,
                        prejoinPageEnabled: false
                    }}
                    interfaceConfigOverwrite = {{
                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                        TOOLBAR_BUTTONS: toolbarButtonsList,
                    }}
                    onApiReady = { (externalApi) => {
                        handlerOnApiReady(externalApi);
                    } }
                    containerStyles={{height: "100%"}}
                />
            }
            <div className="d-none"
                 ref={quizRef}
                 data-bs-toggle="modal"
                 data-bs-target="#quizRoom"></div>
            <div className="d-none"
                 ref={profileRef}
                 data-bs-toggle="offcanvas"
                 data-bs-target="#offcanvasAuthProfile"
                 aria-controls="offcanvasAuthProfile"></div>
            <div className="d-none"
                 ref={socialRef}
                 data-bs-toggle="offcanvas"
                 data-bs-target="#offcanvasSocial"
                 aria-controls="offcanvasSocial"></div>
            <div className="d-none"
                 ref={infoRoomRef}
                 data-bs-toggle="modal"
                 data-bs-target="#infoRoom"></div>
            <div className="d-none"
                 ref={inviteRoomRef}
                 data-bs-toggle="modal"
                 data-bs-target="#inviteRoom"></div>
            <div className="d-none"
                 ref={editRoomRef}
                 data-bs-toggle="modal"
                 data-bs-target="#editRoom"></div>
            <div className="d-none"
                 ref={deleteRoomRef}
                 data-bs-toggle="modal"
                 data-bs-target={`#confirmRoomDeleteModalId=${room.room_id}`}></div>
            <ModalGames />
            {room.room_id && <ModalInfoRoom />}
            <ModalEditRoom />
            <ModalInviteUser />
            <ModalDeleteRoom room_id={room.room_id}/>

            <RoomGames />
        </div>
    )
};