import {generateAttributes} from "../../utils/htmlHelpers";

export const ButtonPurpleBorder = ({icon: Icon, iconSize, text, attributes = [], onClick = null, className = ''}) => {

    return (
        <button
            className={`width-100Percent btn-dark-default btn-be-purple-border ${className}`}
            type="button"
            {...generateAttributes(attributes)}
            onClick={onClick}
        >
            {Icon && <Icon size={iconSize} />} {text}
        </button>
    );
}