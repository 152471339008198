const initialState = [{
    id: null,
    type: "",
    description: ""
}];

export const quizTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_QUIZ_TYPES':
            return action.payload;
        default:
            return state;
    }
};