export const setFollows = (follows) => ({
    type: 'SET_FOLLOWS',
    payload: follows
});

export const followUser = (follower, following) => ({
    type: 'FOLLOW_USER',
    payload: { follower, following }
});

export const unfollowUser = (follower_id, following_id) => ({
    type: 'UNFOLLOW_USER',
    payload: { follower_id, following_id }
});

export const updateUser = (updatedUser) => ({
    type: 'UPDATE_USER',
    payload: updatedUser
});