import {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast, Slide} from 'react-toastify';
import {WSContext} from "../context/WSContext";
import {
    addConnectedUserToOneRoom,
    removeConnectedUserFromOneRoom,
    removeOneRoom,
    updateOneRoom,
    updateRoomAccessOneRoom
} from "../redux/actions/oneRoom";
import {useNavigate} from "react-router-dom";

/**
 * Обработчик ws событий для room page
 */
export const useWebSocketRoom = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ws } = useContext(WSContext);
    const room = useSelector(state => state.oneRoom);


    useEffect(() => {
        if (!ws) return;

        // Обработчик когда пользователь присоединился к комнате
        ws.on('user-joined', ({new_user}) => {
            console.log('user join', new_user);
            dispatch(addConnectedUserToOneRoom(new_user));
        });

        return () => {
            if (ws) {
                ws.off('user-joined');
            }
        };
    }, [ws, room ]);

    useEffect(() => {
        if (!ws) return;

        // Обработчик когда у пользователя disconnect
        ws.on('user-disconnected', ({room_id, user_id}) => {
            dispatch(removeConnectedUserFromOneRoom(user_id));
            console.log(`User disconnected room: ${user_id}`);
        });

        return () => {
            if (ws) {
                ws.off('user-disconnected');
            }
        };
    }, [ws]);

    useEffect(() => {
        if (!ws) return;

        // Обработчик для обновления комнаты
        ws.on('updated-room', ({data, type}) => {
            console.log(data, type)
            if (type === 'general') {
                dispatch(updateOneRoom(data));
            } else if (type === 'access') {
                dispatch(updateRoomAccessOneRoom(data));
            }
        });

        // Обработчик забаненого пользователя
        ws.on('ban-user', ({room_id, user_id}) => {
            console.log(room_id, user_id)
            toast.info('The owner of the room has banned you and blocked access to the room.', {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Slide,
            });
            navigate('/');
        });

        // Обработчик для удаленной комнаты
        ws.on("deleted-room-in-room", ({room_id}) => {
            console.log("room deleted", room_id);
            dispatch(removeOneRoom());
            toast.info('The owner has removed the rooms. You can join another room or create a new\n' +
                '                                    one yourself.', {
                position: "top-center",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Slide,
            });
            navigate('/');
        });

        return () => {
            if (ws) {
                ws.off('updated-room');
                ws.off('ban-user');
                ws.off('deleted-room-in-room');
            }
        };
    }, [ws]);
};
