import {useDispatch, useSelector} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import http from "../axios";
import {setFollows} from "../redux/actions/follow";
import {WSContext} from "../context/WSContext";
import {setPrivateChats} from "../redux/actions/privateChat";
import {ModalUserInfo} from "./modals/ModalUserInfo";
import {ModalDeleteRoom} from "./modals/ModalDeleteRoom";
import {ModalDebug} from "./modals/ModalDebug";
import {getItemWithExpiry, setItemWithExpiry} from "../utils/localStorage";
import {getUserAuth} from "../axios/userRequests";
import {loginUser} from "../redux/actions/user";
import {AuthProfile} from "./offcanvas/AuthProfile";
import {Social} from "./offcanvas/Social";
import {ToastContainer} from "react-toastify";
import {useWebSocketSocial} from "../hooks/useWebSocketSocial";
import {useWebSocketBasic} from "../hooks/useWebSocketBasic";
import {setLevels} from "../redux/actions/level";
import {setLanguages} from "../redux/actions/language";
import {setCountries} from "../redux/actions/country";

export const BasicComponent = () => {
    const {ws} = useContext(WSContext);
    const user = useSelector(state => state.user);
    const rooms = useSelector(state => state.rooms);
    const dispatch = useDispatch();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        // Функция для предотвращения жестов масштабирования
        const preventZoom = (event) => {
            if (event.scale !== 1) {
                event.preventDefault();
            }
        };

        // Отключаем двойное нажатие и жесты масштабирования
        document.addEventListener('gesturestart', preventZoom);
        document.addEventListener('gesturechange', preventZoom);

        // Очистка событий при размонтировании компонента
        return () => {
            document.removeEventListener('gesturestart', preventZoom);
            document.removeEventListener('gesturechange', preventZoom);
        };
    }, []);

    useEffect(() => {
        const getUserAuthAction = async () => {
            if (getItemWithExpiry('token')) {
                if (user.id === null) {
                    try {
                        const res = await getUserAuth();
                        dispatch(loginUser(res.user));
                        setItemWithExpiry('token', res.token, 2);
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
        }
        getUserAuthAction();
    }, []);

    useEffect(() => {
        http.get('follows')
            .then((res) => {
                dispatch(setFollows(res.data));
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);

    useEffect(() => {
        http.get('users')
            .then((res) => {
                setUsers(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
    }, []);


    useEffect(() => {
        if (ws && user.id) {
            ws.emit("auth-user-connect", user.id);
        }
    }, [ws, user]);

    useEffect(() => {
        if (user.id) {
            http.get('private-chat')
                .then((res) => {
                    console.log(res);
                    dispatch(setPrivateChats(res.data))
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [user.id])

    useEffect(() => {
        http.get('languages-and-levels')
            .then((res) => {
                dispatch(setLevels(res.data.levels));
                dispatch(setLanguages(res.data.languages));
                dispatch(setCountries(res.data.countries));
            })
            .catch((err) => {
                console.log(err);
            })
    } ,[]);

    useWebSocketBasic();
    useWebSocketSocial();

    return (
        <div>
            {(user.id) && <Social/>}

            {rooms.data.map((room) => (
                <div key={room.room_id}>
                    {user.id === room.owner.id && (
                        <ModalDeleteRoom room_id={room.room_id}/>
                    )}
                </div>
            ))}

            {users?.map((user, index) => (
                <ModalUserInfo key={index} userFromRoom={user}/>
            ))}

            {user.id && <AuthProfile/>}

            <ToastContainer/>


            <ModalDebug/>

            {/*{(allUsers &&*/}
            {/*    allUsers.map((follow, index) => (*/}
            {/*            <ModalSocialUserInRoom key={index} follow={follow} />*/}
            {/*    )*/}
            {/*))}*/}
        </div>
    );
}