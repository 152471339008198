import {followUser, unfollowUser} from "../redux/actions/follow";
import {useDispatch, useSelector} from "react-redux";
import {useContext} from "react";
import {WSContext} from "../context/WSContext";
import {followRequests, unfollowRequest} from "../axios/followRequests";

export const useFollows = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const {ws} = useContext(WSContext);

    const onFollow = async (follower) => {
        try {
            if (user.id) {
                await followRequests(user.id, follower.id);
                dispatch(followUser(follower, user));
                ws.emit("follow-user", { follower: follower, following: user });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const unfollow = async (follower_id) => {
        try {
            if (user.id) {
                await unfollowRequest(user.id, follower_id);
                dispatch(unfollowUser(follower_id, user.id));
                ws.emit("unfollow-user", { follower_id: follower_id, following_id: user.id });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const toggleFollow = async (follow, follower) => {
        try {
            if (user.id) {
                if (!follow.is_following) {
                    await onFollow(follow, follower);
                } else {
                    await unfollow(follower.id);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    return {
        onFollow,
        unfollow,
        toggleFollow
    };
}