import http from "./index";

// получить викторину
export const getQuizRequest = async (data) => {
    try {
        const res = await http.get('quiz', {
            params: {
                type_id: data.type_id,
                type: data.type,
                language: data.language
            }
        });
        return res.data.map(question => {
            return {
                ...question,
                options: [...question.options].sort(() => Math.random() - 0.5)
            };
        });
    } catch (err) {
        throw err;
    }
}