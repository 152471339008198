const initialState =
    {
        data: [
            {
                user: {
                    id: null,
                    name: '',
                    avatar: '',
                    country: null
                },
                messages: [
                    {
                        id: null,
                        is_read: 0,
                        type: null,
                        message: '',
                        sender_id: null,
                        created_at: null,
                        updated_at: null
                    }
                ]
            }
        ]
    };

export const privateChatReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PRIVATE_CHATS':
            return {
                ...state,
                data: action.payload
            };
        case 'ADD_MESSAGE_TO_PRIVATE_CHAT': {
            const { user, messages } = action.payload;
            const existingUserIndex = state.data.findIndex(
                chat => chat.user.id === user.id
            );

            if (existingUserIndex !== -1) {
                // Пользователь найден, добавляем сообщение
                const updatedData = state.data.map((chat, index) => {
                    if (index === existingUserIndex) {
                        return {
                            ...chat,
                            messages: [...chat.messages, messages]
                        };
                    }
                    return chat;
                });

                return {
                    ...state,
                    data: updatedData
                };
            } else {
                // Пользователь не найден, создаем нового
                const newUserChat = {
                    user: user,
                    messages: [messages],
                };

                return {
                    ...state,
                    data: [...state.data, newUserChat]
                };
            }
        }
        case 'MARK_MESSAGES_AS_READ': {
            const { user_id, messageIds } = action.payload;
            const updatedData = state.data.map(chat => {
                if (chat.user.id === user_id) {
                    const updatedMessages = chat.messages.map(message => {
                        if (messageIds.includes(message.id)) {
                            return { ...message, is_read: 1 };
                        }
                        return message;
                    });
                    return { ...chat, messages: updatedMessages };
                }
                return chat;
            });

            return {
                ...state,
                data: updatedData
            };
        }
        case 'DELETE_PRIVATE_CHAT': {
            const { user_id } = action.payload;
            const updatedData = state.data.filter(chat => chat.user.id !== user_id);

            return {
                ...state,
                data: updatedData
            };
        }
        default:
            return state;
    }
};