import {useCallback, useContext, useState} from "react";
import {RoomContext} from "../../context/RoomContext";
import {Wheel} from "react-custom-roulette";
import {ButtonPurpleBorder} from "../buttons/ButtonPurleBorder";

export const RouletteSpin = () => {
    const {
        items,
        rouletteMap,
        isSpinning,
        rouletteResult
    } = useContext(RoomContext);

    const [selectedOption, setSelectedOption] = useState('')
    const handleSpinClick = () => {
        if (!isSpinning) {
            const newPrizeNumber = Math.floor(Math.random() * items.length);

            rouletteMap.current.set('roulette', {
                ...rouletteMap.current.get('roulette'),
                rouletteResult: newPrizeNumber,
                isSpinning: true
            });
        }
    }

    const handleStopSpinning = () => {
        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            isSpinning: false
        });
        setSelectedOption(items[rouletteResult].option)
    }

    const changeRoulette = useCallback(() => {
        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            stepRoulette: 'list',
            rouletteType: null,
            isSpinning: false,
            items: []
        });
    }, [rouletteMap]);

    return (
        <>
            <div className="text-center m-2">
                <button type="button" className="btn btn-secondary width-250px" onClick={changeRoulette}>Change roulette</button>
            </div>
            <div className="d-flex justify-content-center">
                <Wheel
                    mustStartSpinning={isSpinning}
                    prizeNumber={rouletteResult}
                    data={items}
                    onStopSpinning={handleStopSpinning}
                    backgroundColors={['#00c75d', '#ff602e']}
                    textColors={['#ffffff']}
                    textDistance={55}
                    spinDuration={0.5}
                />
            </div>
            <div className="text-center m-2">
                <ButtonPurpleBorder
                    onClick={handleSpinClick}
                    text='SPIN'
                    className='width-250px'
                />
            </div>
            <p className="text-white mx-2">Result: {selectedOption && selectedOption}</p>
        </>
    );
};