import http from "./index";

// получить roulette
export const getRouletteRequest = async (data) => {
    try {
        const res = await http.get('roulette', {
            params: {
                type_id: data.type_id,
                topic: data.topic,
                language: data.language
            }
        });
        return res.data;
    } catch (err) {
        throw err;
    }
}