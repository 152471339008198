import React, {useCallback, useContext} from "react";
import {RoomContext} from "../../context/RoomContext";
import {QuizJoin} from "./QuizJoin";
import {ArrowLeftCircle} from "react-bootstrap-icons";
import {QuizLoading} from "./QuizLoading";
import {QuizInProgress} from "./QuizInProgress";
import {QuizFinish} from "./QuizFinish";
import {QuizList} from "./QuizList";

export const Quiz = () => {
    const {
        quizMap,
        quizType,
        localShowQuiz,
        setLocalShowQuiz,
        stepQuiz,
        setStepQuiz
    } = useContext(RoomContext);

    const closeQuiz = useCallback(() => {
        quizMap.current.set('quiz', {
            ...quizMap.current.get('quiz'),
            stepQuiz: 'list',
            isActiveQuiz: false,
            quizType: null,
            usersQuiz: [],
            quiz: []
        });
    }, [quizMap, setLocalShowQuiz]);

    const renderQuizContent = () => {
        if (stepQuiz === 'list') {
            return <QuizList/>
        } else if (stepQuiz === 'join') {
            return <QuizJoin/>
        } else if (stepQuiz === 'loading') {
            return <QuizLoading/>
        } else if (stepQuiz === 'in-progress') {
            return <QuizInProgress/>
        } else if (stepQuiz === 'finish') {
            return <QuizFinish closeQuiz={closeQuiz}/>
        }
    }

    return (
        <React.Fragment>
            <div className={`wrapper-quiz-active ${localShowQuiz && 'show'}`}>
                <div className="header-quiz d-flex justify-content-between">
                    <div className="m-2">
                        <button type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => setLocalShowQuiz(false)}>
                            Hide
                        </button>
                    </div>

                    <div>
                        <p className="quiz-name text-white" onClick={() => setStepQuiz('join')}>{quizType ? quizType.type : 'List of quizzes'}</p>
                    </div>

                    <span className="m-2">
                        <button type="button" className="btn btn-danger" onClick={closeQuiz}>Close</button>
                    </span>
                </div>
                <div className="content-quiz overflow-auto border-bottom">
                    {renderQuizContent()}
                </div>
            </div>
        </React.Fragment>
    );
}