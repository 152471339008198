import {useSelector} from "react-redux";
import {PersonSquare} from "react-bootstrap-icons";
import React from "react";

export const ButtonAuth = () => {
    const user = useSelector(state => state.user);
    return (
        <button className="button-auth-user btn-light-default btn-be-birch d-flex justify-content-between align-items-center"
                data-bs-toggle="offcanvas" data-bs-target="#offcanvasAuthProfile"
                aria-controls="offcanvasAuthProfile"
        >
            <div className="d-flex align-items-center">
                <img
                    src={user?.avatar}
                    alt="Jessica Potter" className=""/>
            </div>
            <div className="d-flex align-items-center justify-content-center">
                <PersonSquare size={20}/>
            </div>
        </button>
    );
}