import {PlusCircle} from "react-bootstrap-icons";
import React, {useContext} from "react";
import {WSContext} from "../../context/WSContext";
import {useDispatch, useSelector} from "react-redux";
import {getAllUsersWithFriendshipAndRoomStatus} from "../../redux/selectors/followsSelectors";
import {deleteUserFromBlackList} from "../../axios/blackListRequests";
import {Slide, toast} from "react-toastify";
import {Tooltip} from "react-tooltip";
import {removeUserFromBlackList} from "../../redux/actions/oneRoom";

export const ModalInviteUser = () => {
    const {ws} = useContext(WSContext);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const room = useSelector(state => state.oneRoom);
    const {myFollowing, friends_count} = useSelector(state => getAllUsersWithFriendshipAndRoomStatus(state, user.id));

    const sendInvite = async (user_id) => {
        try {
            const hasBlackList = room.black_list.some(id => id === user_id);
            console.log(hasBlackList);
            if (hasBlackList) {
                const data = {
                    room_id: room.room_id,
                    user_id: user_id,
                }
                await deleteUserFromBlackList(data);
                dispatch(removeUserFromBlackList(user_id));
            }
            ws.emit('invite-user-room', {room, user_id, user})
            toast.success('The invitation has been sent.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="modal fade" id="inviteRoom" tabIndex="-1" aria-labelledby="inviteRoom"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content bg-dark-modal text-white border-modal">
                    <div className="modal-header d-flex justify-content-between">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <PlusCircle
                                size={30}
                                className='help'
                                data-tooltip-id="access-room"
                                data-tooltip-content="You can invite your friend to the room."/>
                            <span className='mx-2'>Invite user</span>
                        </h1>
                        <Tooltip id="access-room"/>
                        <div data-bs-theme="dark" className="close-btn-modal">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        {friends_count > 0 ? (
                            myFollowing.map((friend) => (
                                friend.is_friend && (
                                    <div className="d-flex justify-content-between align-items-center my-2 border-bottom" key={friend.id}>
                                        <div className="d-flex align-items-center invite-user-content my-2">
                                            <img src={friend.avatar} alt="avatar" className="me-2" />
                                            <p className="mb-0">{friend.name}</p>
                                        </div>
                                        <div className="icon-send-invite">
                                            <PlusCircle size={26} className='pointer' onClick={() => sendInvite(friend.id)} />
                                        </div>
                                    </div>
                                )
                            ))
                        ) : (
                            <p className='d-flex justify-content-center'>
                                You have no friends.
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}