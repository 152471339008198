import http from "./index";

// получить типы викторин
export const getQuizTypesRequest = async () => {
    try {
        const res = await http.get('quiz-types');
        return res.data;
    } catch (err) {
        throw err;
    }
}