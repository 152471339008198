import React from "react";
import {useSelector} from "react-redux";

export const FilterRoom = ({selectedLanguage, setSelectedLanguage, selectedLevel, setSelectedLevel}) => {
    const levels = useSelector(state => state.levels);
    const languages = useSelector(state => state.languages);

    return (
        <div className="d-flex border-bottom my-2 p-2">
            <div className="mx-1">
                <p className="text-white">Language</p>
                <select
                    className={`form-select text-white bg-dark-input pointer`}
                    aria-label="language-select"
                    value={selectedLanguage}
                    name="language"
                    onChange={(e) => setSelectedLanguage(e.target.value)}
                >
                    <option value="Any">Any Language</option> {/* Опция для "Any" */}
                    {languages.map((lang, index) => (
                        <option key={index} value={lang.language}>{lang.language}</option>
                    ))}
                </select>
            </div>

            <div className="mx-1">
                <p className="text-white">Level</p>
                <select
                    className={`form-select text-white bg-dark-input pointer`}
                    aria-label="level-select"
                    value={selectedLevel}
                    name="level"
                    onChange={(e) => setSelectedLevel(e.target.value)}
                >
                    <option value="Any">Any Level</option> {/* Опция для "Any" */}
                    {levels.map((lvl, index) => (
                        <option key={index} value={lvl.level}>{lvl.level}</option>
                    ))}
                </select>
            </div>
        </div>
    )
}