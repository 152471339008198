import {PencilSquare, ShieldLock} from "react-bootstrap-icons";
import React, {useContext, useEffect, useState} from "react";
import {Tooltip} from "react-tooltip";
import {ToggleButton} from "../buttons/ToggleButton";
import {useDispatch, useSelector} from "react-redux";
import {WSContext} from "../../context/WSContext";
import {updateAccessRoomById, updateRoomById} from "../../axios/roomRequests";
import {updateOneRoom, updateRoomAccessOneRoom} from "../../redux/actions/oneRoom";
import {EnumPeopleList} from "../../enums/PeopleList";
import {Slide, toast} from "react-toastify";

export const ModalEditRoom = () => {
    const dispatch = useDispatch();
    const {ws} = useContext(WSContext);
    const room = useSelector(state => state.oneRoom);
    const levels = useSelector(state => state.levels);
    const languages = useSelector(state => state.languages);
    const [description, setDescription] = useState('');
    const [selectedLanguage, setSelectedLanguage] = useState({id: null, language: null});
    const [selectedLevel, setSelectedLevel] = useState({id: null, level: null});
    const [selectedPeople, setSelectedPeople] = useState(0);
    const [errorForm, setErrorForm] = useState({});

    useEffect(() => {
        setSelectedLanguage({id: room.language_id, language: room.language});
        setSelectedLevel({id: room.level_id, level: room.level});
        setSelectedPeople(room.quantity);
        setDescription(room.description);
    }, [room]);

    const sendUpdateRoom = async () => {
        if (
            selectedLanguage.language === room.language &&
            selectedLevel.level === room.level &&
            selectedPeople === room.quantity &&
            description === room.description
        ) {
            return;
        }
        let er = false;
        if (typeof description !== 'string' || description.length > 300) {
            setErrorForm({description: 'Maximum description length 300 characters'});
            er = true
        } else {
            setErrorForm({description: ''})
        }
        if (er === true) {
            return;
        }
        const data = {
            room_id: room.room_id,
            language_id: selectedLanguage.id,
            level_id: selectedLevel.id,
            description: description,
            quantity: selectedPeople
        }
        try {
            await updateRoomById(data);
            data.language = selectedLanguage.language;
            data.level = selectedLevel.level;
            dispatch(updateOneRoom(data));
            if (ws) {
                ws.emit('update-room', {room_id: room.room_id, data: data, type: 'general'});
                ws.emit('home-update-room', {data: data, type: 'general'});
            }
            toast.success('The room has been updated.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        } catch (err) {
            toast.error('Something went wrong...', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }
    }

    const sendUpdateAccessRoomById = async () => {
        try {
            const data = {
                room_id: room.room_id,
                access: room.access === 1 ? 0 : 1
            };
            await updateAccessRoomById(data);
            dispatch(updateRoomAccessOneRoom(data));
            if (ws) {
                ws.emit('update-room', {room_id: room.room_id, data: data, type: 'access'});
                ws.emit('home-update-room', {data: data, type: 'access'});
            }
            toast.success('The room has been updated.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
            return true;
        } catch (err) {
            toast.error('Something went wrong...', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
            return false;
        }
    }
    const handleChangeLanguage = (event) => {
        const foundLang = {id: languages[Number(event.target.value)].id, language: languages[Number(event.target.value)].language};
        setSelectedLanguage(foundLang);
    };

    const handleChangeLevel = (event) => {
        const foundLvl = {id: levels[Number(event.target.value)].id, level: levels[Number(event.target.value)].level};
        setSelectedLevel(foundLvl);
    };

    const handleChangePeople = (event) => {
        if (event.target.value === 'Unlimited') {
            setSelectedPeople(0);
        } else {
            setSelectedPeople(Number(event.target.value));
        }
    };
    return (
        <div className="modal fade" id="editRoom" tabIndex="-1" aria-labelledby="editRoom"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content bg-dark-modal text-white border-modal">
                    <div className="modal-header d-flex justify-content-between">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                            <PencilSquare size={30}/> <span className='mx-2'>Edit Room</span>
                        </h1>
                        <div data-bs-theme="dark" className="close-btn-modal">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="form">
                            <p className="font m-0">Language</p>
                            <select
                                className="form-select bg-dark-input text-white"
                                aria-label="language-select"
                                value={selectedLanguage}
                                onChange={handleChangeLanguage}
                                name="language">
                                    <option selected>{selectedLanguage?.language}</option>
                                    {languages.map((lang, index) => (
                                        <option key={index} value={index}>{lang.language}</option>
                                    ))}
                            </select>
                            <p className="font m-0 mt-3">Level</p>
                            <select
                                className="form-select bg-dark-input text-white"
                                aria-label="level-select"
                                value={selectedLevel}
                                onChange={handleChangeLevel}
                                name="level">
                                <option selected>{selectedLevel?.level}</option>
                                {levels.map((lvl, index) => (
                                    <option key={index} value={index}>{lvl.level}</option>
                                ))}
                            </select>

                            <div className="mt-3">
                                <label className="text-white font" htmlFor="description">Description</label>
                                <input className="form-control text-white bg-dark-input description"
                                       type="text"
                                       placeholder="Description"
                                       aria-label="default input example"
                                       id="description"
                                       maxLength={300}
                                       value={description}
                                       onChange={(e) => setDescription(e.target.value)} />
                                {errorForm.description && (
                                    <span className="text-danger">{errorForm.description}</span>
                                )}
                            </div>
                        </div>
                        <p className="font m-0 mt-3">Maximum People</p>
                        <select
                            className="form-select bg-dark-input text-white"
                            aria-label="level-select"
                            value={selectedPeople}
                            onChange={handleChangePeople}
                            name="people">
                            <option selected>{selectedPeople === 0 ? 'Unlimited' : selectedPeople}</option>
                            {EnumPeopleList.map((people, index) => (
                                <option key={index} value={index}>{people.label}</option>
                            ))}
                        </select>

                        <div className='mt-3'>
                            <div className="d-grid gap-2">
                                <button className="btn btn-primary" type="button" onClick={sendUpdateRoom}>Update</button>
                            </div>
                        </div>

                        <hr/>

                        <div className='d-flex mx-2 my-3'>
                            <div>
                                <ShieldLock className="help"
                                    size={25}
                                    data-tooltip-id="access-room"
                                    data-tooltip-content="You can block access to the room for new users."/>
                                <Tooltip id="access-room"/>
                            </div>
                            <div className='mx-3'>
                                <ToggleButton text1="Public" text2="Private" toggle={!room.access}
                                              onToggle={sendUpdateAccessRoomById}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}