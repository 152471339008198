import React, {createContext, useEffect, useRef, useState} from "react";
import * as Y from "yjs";
import {WebsocketProvider} from "y-websocket";
import {useSelector} from "react-redux";

export const RoomContext = createContext(null);

export const RoomProvider = ({children}) => {
    const room = useSelector(state => state.oneRoom);

    /**
     * Yjs
     */
    const ywsHost = process.env.REACT_APP_YWS_HOST || 'https://speak-stream-yws-server-dev-81cf2bb8945f.herokuapp.com';
    const yDoc = useRef(null);

    /**
     * Quiz data
     */
    const quizMap = useRef(null);
    const [localShowQuiz, setLocalShowQuiz] = useState(false);
    const [isActiveQuiz, setIsActiveQuiz] = useState(false);
    const [quizType, setQuizType] = useState('');
    const [quiz, setQuiz] = useState([]);
    const [stepQuiz, setStepQuiz] = useState('list');
    const [usersQuiz, setUsersQuiz] = useState([]);

    /**
     * RouletteSpin data
     */
    const rouletteMap = useRef(null);
    const [localShowRoulette, setLocalShowRoulette] = useState(false);
    const [isActiveRoulette, setIsActiveRoulette] = useState(false);
    const [stepRoulette, setStepRoulette] = useState('list');
    const [isSpinning, setIsSpinning] = useState(false);
    const [rouletteResult, setRouletteResult] = useState();
    const [items, setItems] = useState([]);
    const [rouletteType, setRouletteType] = useState('');

    useEffect(() => {
        if (!room.room_id) return;

        yDoc.current = new Y.Doc();
        quizMap.current = yDoc.current.getMap('quiz');
        rouletteMap.current = yDoc.current.getMap('roulette');
        const provider = new WebsocketProvider(ywsHost, room.room_id, yDoc.current);
        // Проверяем, подключён ли WebSocket провайдер
        if (!provider.connected) {
            provider.connect();
        }

        const observerQuiz = event => {
            const state = quizMap.current.get('quiz');
            if (state) {
                if (state.isActiveQuiz === false) {
                    setLocalShowQuiz(false);
                }
                setQuizType(state.quizType || quizType);
                setQuiz(state.quiz || quiz);
                setUsersQuiz(state.usersQuiz || usersQuiz);
                setStepQuiz(state.stepQuiz || stepQuiz);
                setIsActiveQuiz(state.isActiveQuiz || isActiveQuiz);
            }
        };

        const observerRoulette = event => {
            const state = rouletteMap.current.get('roulette');
            if (state) {
                setIsActiveRoulette(state.isActiveRoulette || isActiveRoulette);
                setIsSpinning(state.isSpinning || isSpinning);
                setRouletteResult(state.rouletteResult || 0);
                setItems(state.items || items);
                setRouletteType(state.rouletteType || rouletteType);
                setStepRoulette(state.stepRoulette || stepRoulette);
            }
        };

        // Добавляем наблюдателя
        quizMap.current.observe(observerQuiz);
        rouletteMap.current.observe(observerRoulette);

        // Очистка: отключаем соединение и убираем наблюдателя
        return () => {
            quizMap.current.unobserve(observerQuiz);
            rouletteMap.current.unobserve(observerRoulette);

            if (provider.connected) {
                provider.disconnect();
            }
            yDoc.current.destroy();
            provider.destroy();
        };
    }, [room.room_id]);


    return (
        <RoomContext.Provider value={{
            quizMap,
            isActiveQuiz,
            setIsActiveQuiz,
            quizType,
            setQuizType,
            quiz,
            setQuiz,
            localShowQuiz,
            setLocalShowQuiz,
            stepQuiz,
            setStepQuiz,
            usersQuiz,
            setUsersQuiz,

            rouletteMap,
            isActiveRoulette,
            setIsActiveRoulette,
            localShowRoulette,
            setLocalShowRoulette,
            stepRoulette,
            setStepRoulette,
            isSpinning,
            setIsSpinning,
            rouletteResult,
            setRouletteResult,
            items,
            setItems,
            rouletteType,
            setRouletteType
        }}>
            {children}
        </RoomContext.Provider>
    );
};