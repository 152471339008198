import React, {useContext, useEffect} from "react";
import {removeUserFromBlackList} from "../redux/actions/room";
import {Slide, toast} from "react-toastify";
import {BoxArrowLeft} from "react-bootstrap-icons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {WSContext} from "../context/WSContext";

export const useWebSocketBasic = () => {
    const navigate = useNavigate();
    const {ws} = useContext(WSContext);
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const joinRoom = (room) => {
        console.log(`Joining room: ${room.room_id}`);
        navigate(`/room/${room.room_id}`);
    };

    useEffect(() => {
        if (!user.id || !ws) return;

        ws.on('invite-user', ({room, user_id,  user}) => {
            console.log('invite', room, user);
            dispatch(removeUserFromBlackList(room.room_id, user_id));
            toast.info(
                <div>
                    {user.name} invited you into the room.{' '}
                    <span className='text-underline text-silver' onClick={() => joinRoom(room)} >
                         {'  '} <BoxArrowLeft /> Join
                    </span>
                </div>,
                {
                    position: 'bottom-right',
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                    transition: Slide,
                }
            );
        });

        return () => {
            if (ws) {
                ws.off('invite-user');
            }
        }
    }, [user.id, ws])
}