import React from 'react';
import {PopoverOwnRoom} from "./PopoverOwnRoom";
import {BoxArrowInLeft, Trash} from "react-bootstrap-icons";
import {useSelector} from "react-redux";
import {useModalContext} from "../context/ModalContext";
import {ButtonPurple} from "./buttons/ButtonPurple";
import {useNavigate} from "react-router-dom";
import {Slide, toast} from "react-toastify";
import {truncateString} from "../utils/htmlHelpers";
import {Tooltip} from "react-tooltip";

const Card = ({ room, isModal = false }) => {
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const { isModalInModal, setIsModalInModal } = useModalContext();

    const isShowIconDeleteRoom = () => {
        return user.id === room.owner.id;
    }

    const isBan = room.black_list.includes(user.id);
    const isPrivate = (room.owner.id !== user.id && !room.access);
    const isFull = room.quantity !== 0 && room.connected_users_count >= room.quantity;

    const dataAttributeCreateRoom = () => {
        return (!user.id) ? [
            {key: 'data-bs-toggle', value: 'modal'},
            {key: 'data-bs-target', value: '#ModalForUserNotAuth'}
        ] : [];
    }

    const closeAttributes = isModal ? {
        'aria-hidden': "true", 'data-dismiss': "modal", 'data-bs-dismiss': "modal", 'aria-label': "Close"
    } : {};
    const joinToRoom = () => {
        if (user.id) {
            if (isBan) {
                toast.warn('The owner of the room has banned you and blocked access to the room.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide,
                });
                console.log(room);
            } else if (isPrivate) {
                toast.warn('This is a private room.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide,
                });
            } else if (isFull) {
                toast.warn('The room is full.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Slide,
                });
            } else {
                navigate(`/room/${room.room_id}`);
            }
        }
    }

    if (!room) {
        return null;
    }
    return (
        <div className="col">
            <div className="card-room text-white d-flex flex-column">
                <div className="header-card-room border-bottom">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <h3>{room.language}</h3>
                            <span className="m-2">{room.level}</span>
                        </div>
                        <div className="d-flex">
                            {isShowIconDeleteRoom() && (
                                <div className="m-1">
                                    <Trash
                                        className="pointer"
                                        color={"tomato"}
                                        size={24}
                                        data-bs-toggle="modal"
                                        data-bs-target={`#confirmRoomDeleteModalId=${room.room_id}`}
                                        onClick={() => setIsModalInModal(isModal)}
                                    />
                                </div>
                            )}
                            <div className="m-1">
                                <PopoverOwnRoom owner={room.owner} />
                            </div>
                        </div>
                    </div>
                    <p data-tooltip-id={`room-desc-${room.room_id}`}
                       data-tooltip-content={room.description}
                    >{truncateString(room.description, 34)}</p>
                    <Tooltip id={`room-desc-${room.room_id}`}/>
                </div>
                <div className="d-flex flex-wrap align-items-center card-room-users">
                    {room.connected_users_count !== 0 ?
                        (room.connected_users.map((userFromRoom, index) => (
                            <div key={index}>
                                <img src={userFromRoom.avatar} alt={`Avatar ${index + 1}`}
                                     className="img-fluid rounded-circle pointer m-1 modal-border"
                                     data-bs-toggle="modal"
                                     data-bs-target={`#userInfoModalId=${userFromRoom.id}`}
                                     onClick={() => setIsModalInModal(isModal)} />
                            </div>
                        )))
                        :
                        <div className="w-100">
                            <p className="text-center">Speak Stream</p>
                        </div>
                    }
                </div>
                <div className="d-flex justify-content-between align-items-center m-1 mt-auto">
                    <span>{room.connected_users_count}/{room.quantity === 0 ? 'Unlimited' : room.quantity}</span>
                    <div className="width-150px">
                        <ButtonPurple
                            text="Join"
                            icon={BoxArrowInLeft}
                            iconSize={24}
                            onClick={joinToRoom}
                            attributes={dataAttributeCreateRoom()}
                            className={((isBan || isPrivate || isFull) ? 'not-allowed' : '')}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
