import {addDays, isAfter, parseISO} from 'date-fns';

/**
 * Добавить данные в local storage с истечением срока
 */
export const setItemWithExpiry = (key, value, days) => {
    const now = new Date();
    const expiryDate = addDays(now, days);

    const item = {
        value: value,
        expiry: expiryDate.toISOString(),
    };
    localStorage.setItem(key, JSON.stringify(item));
}

/**
 * Получить данные с local storage. Если время истекло, возвращается null.
 */
export const getItemWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (isAfter(now, parseISO(item.expiry))) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}