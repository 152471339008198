import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

export const useDropdownList = () => {
    const user = useSelector(state => state.user);
    const room = useSelector(state => state.oneRoom);
    const levels = useSelector(state => state.levels);
    const languages = useSelector(state => state.languages);
    const countries = useSelector(state => state.countries);
    const peopleOptions = [
        { id: 0, label: 'Unlimited' },
        { id: 1, label: '1' },
        { id: 2, label: '2' },
        { id: 3, label: '3' },
        { id: 4, label: '4' },
        { id: 5, label: '5' },
        { id: 6, label: '6' },
        { id: 7, label: '7' },
        { id: 8, label: '8' },
        { id: 9, label: '9' },
        { id: 10, label: '10' },
    ];

    /**
     * dropdown list language
     */
    const [isOpenDropdownLanguage, setIsOpenDropdownLanguage] = useState(false);
    const initSelectedLanguage = () => {
        if (room.room_id) {
            return {
                id: room.language_id,
                language: room.language
            }
        } else {
            return {
                id: null,
                language: 'Choose language'
            };
        }
    }
    const [selectedLanguage, setSelectedLanguage] = useState(initSelectedLanguage());
    // Функция для обработки клика на кнопку
    const toggleDropdownLanguage = () => {
        setIsOpenDropdownLanguage(!isOpenDropdownLanguage);
    };
    // Функция для обработки выбора элемента
    const handleLanguageClick = (id) => {
        setSelectedLanguage({id: languages[id].id, language: languages[id].language});
        setIsOpenDropdownLanguage(false);
    };


    /**
     * dropdown list level
     */
    const [isOpenDropdownLevel, setIsOpenDropdownLevel] = useState(false);
    const initSelectedLevel = () => {
        if (room.room_id) {
            return {
                id: room.level_id,
                level: room.level
            }
        } else {
            return {
                id: null,
                level: 'Choose level'
            };
        }
    }
    const [selectedLevel, setSelectedLevel] = useState(initSelectedLevel());
    const toggleDropdownLevel = () => {
        setIsOpenDropdownLevel(!isOpenDropdownLevel);
    };
    const handleLevelClick = (id) => {
        setSelectedLevel({id: levels[id].id, level: levels[id].level});
        setIsOpenDropdownLevel(false);
    };

    /**
     * dropdown list people
     */
    const [isOpenDropdownPeople, setIsOpenDropdownPeople] = useState(false);
    const initSelectedPeople = () => {
        if (room.room_id) {
            return room.quantity;
        } else {
            return 0;
        }
    }
    const [selectedPeople, setSelectedPeople] = useState(initSelectedPeople());
    const toggleDropdownPeople = () => {
        setIsOpenDropdownPeople(!isOpenDropdownPeople);
    };
    const handlePeopleClick = (id) => {
        setSelectedPeople(id);
        setIsOpenDropdownPeople(false);
    };

    /**
     * dropdown list country
     */
    const [isOpenDropdownCountry, setIsOpenDropdownCountry] = useState(false);
    const initSelectedCountry = () => {
            return {
                id: user.country_id,
                code: user.code,
                country: user.country
            }
    }
    const [selectedCountry, setSelectedCountry] = useState(initSelectedCountry());
    // Функция для обработки клика на кнопку
    const toggleDropdownCountry = () => {
        setIsOpenDropdownCountry(!isOpenDropdownCountry);
    };
    // Функция для обработки выбора элемента
    const handleCountryClick = (id) => {
        setSelectedCountry({id: countries[id].id, code: countries[id].code, country: countries[id].country});
        setIsOpenDropdownCountry(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Проверяем, было ли кликнуто вне popover
            if (isOpenDropdownLanguage && !event.target.closest('.drop-language')) {
                setIsOpenDropdownLanguage(false);
            }
            if (isOpenDropdownLevel && !event.target.closest('.drop-level')) {
                setIsOpenDropdownLevel(false);
            }
            if (isOpenDropdownPeople && !event.target.closest('.drop-people')) {
                setIsOpenDropdownPeople(false);
            }
        };

        // Добавляем обработчик события для клика по всему документу
        document.addEventListener('click', handleOutsideClick);

        return () => {
            // Удаляем обработчик события при размонтировании компонента
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpenDropdownLanguage, isOpenDropdownLevel, isOpenDropdownPeople]);

    return {
        isOpenDropdownLanguage,
        setIsOpenDropdownLanguage,
        selectedLanguage,
        setSelectedLanguage,
        toggleDropdownLanguage,
        handleLanguageClick,
        isOpenDropdownLevel,
        setIsOpenDropdownLevel,
        selectedLevel,
        setSelectedLevel,
        toggleDropdownLevel,
        handleLevelClick,
        isOpenDropdownPeople,
        setIsOpenDropdownPeople,
        selectedPeople,
        setSelectedPeople,
        peopleOptions,
        toggleDropdownPeople,
        handlePeopleClick,
        isOpenDropdownCountry,
        setIsOpenDropdownCountry,
        selectedCountry,
        setSelectedCountry,
        toggleDropdownCountry,
        handleCountryClick
    };
}