import {useState} from "react";

export const ToggleButton = ({ text1, text2, toggle = false, onToggle = false }) => {
    const [toggled, setToggled] = useState(toggle);

    const handleClick = async () => {
        try {
            if (onToggle) {
                const result = await onToggle();
                if (result) {
                    setToggled(!toggled);
                } else {
                    console.error("Toggle function did not return true.");
                }
            } else {
                setToggled(!toggled);
            }
        } catch (error) {
            console.error("Error occurred in toggle function:", error);
        }
    };

    return (
        <button
            className={`toggle-button ${toggled ? 'default' : 'toggled'}`}
            onClick={handleClick}
        >
            {toggled ? text1 : text2}
        </button>
    );
};