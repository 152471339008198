const initialState = [{
    id: null,
    code: "",
    language: ""
}];

export const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_LANGUAGES':
            return action.payload;
        default:
            return state;
    }
};