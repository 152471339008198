const initialState = {};

export const peerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_PEER':
            return {
                ...state,
                [action.payload.peerId]: {
                    stream: action.payload.remoteStream,
                }
            };
        case 'REMOVE_PEER':
            const { [action.payload.peerId]: removed, ...rest } = state;
            return { ...rest };
        default:
            return {...state};
    }
};