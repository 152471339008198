import React, {useCallback, useContext} from "react";
import {RoomContext} from "../../context/RoomContext";
import {useSelector} from "react-redux";

export const QuizFinish = ({closeQuiz}) => {
    const user = useSelector(state => state.user);
    const {quizMap, usersQuiz} = useContext(RoomContext);

    const goToList = useCallback(() => {
        quizMap.current.set('quiz', {
            ...quizMap.current.get('quiz'),
            stepQuiz: 'list',
            quizType: null,
            usersQuiz: [],
            quiz: []
        });
    }, [quizMap]);

    return (
        <div>
            <div className="wrapper-wight-content d-flex gap-4 col-6 mx-auto my-2">
                <button className="btn btn-primary w-100" type="button" onClick={goToList}>Go to list</button>
                <button className="btn btn-danger w-100" type="button" onClick={closeQuiz}>Close Quiz</button>
            </div>
            {usersQuiz
                .slice() // Создаем копию массива
                .sort((a, b) => b.score - a.score) // Сортируем по убыванию score
                .map((currentUser, index) => {
                    if (index === 0) {
                        return (
                            <div className="d-flex justify-content-center">
                                <div key={currentUser.id} className="bg-success text-center wrapper-wight-content">
                                    <h3 className="text-white">Winner</h3>
                                    <div
                                        className={`d-flex justify-content-center align-items-center invite-user-content p-2 text-white`}
                                    >
                                        <img src={currentUser.avatar} alt="avatar" className="me-2" />
                                        <p className="mb-0 mx-2">{currentUser.name}</p>
                                        <p className="mb-0">{currentUser.score}</p>
                                    </div>
                                    <hr />
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="d-flex justify-content-center my-2">
                                <div
                                    className={`wrapper-wight-content d-flex align-items-center invite-user-content p-2 text-white border-bottom ${currentUser.id === user.id && 'quiz-progress-score-me'}`}
                                    key={currentUser.id}
                                >
                                    <div className="border-right mx-1">
                                        <p className="position-quiz text-center">{index + 1}</p>
                                    </div>
                                    <img src={currentUser.avatar} alt="avatar" className="me-2" />
                                    <p className="mb-0 mx-2">{currentUser.name}</p>
                                    <p className="mb-0">{currentUser.score}</p>
                                </div>
                            </div>
                        );
                    }
                })}
        </div>
    );
}