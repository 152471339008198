import {createSelector} from 'reselect';

const getCurrentUserId = (state, currentUserId) => currentUserId;
const getFollows = state => state.follows.follows;
const getRooms = state => state.rooms.data;


// Селектор для получения всех пользователей с информацией о дружбе и комнатах
export const getAllUsersWithFriendshipAndRoomStatus = createSelector(
    [getFollows, getRooms, getCurrentUserId],
    (follows, rooms, currentUserId) => {
        if (!currentUserId) return [];

        const currentUser = follows.find(user => user.id === currentUserId);
        if (!currentUser) return [];

        const followersCount = currentUser.followers.length;
        const followingCount = currentUser.following.length;
        const friendsCount = currentUser.following.filter(followingUser =>
            currentUser.followers.some(follower => follower.id === followingUser.id)
        ).length;

        const updatedFollowing = currentUser.following.map(followingUser => {
            const isFriend = currentUser.followers.some(follower => follower.id === followingUser.id);
            const room = rooms.find(room => room.connected_users.some(user => user.id === followingUser.id));
            const isInRoom = Boolean(room);

            return {
                ...followingUser,
                is_friend: isFriend,
                inRoom: isInRoom,
                room: room || null
            };
        });

        return {
            myFollowing: updatedFollowing,
            followers_count: followersCount,
            following_count: followingCount,
            friends_count: friendsCount,
        };
    }
);