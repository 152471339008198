export const addRoom = (room) => ({
    type: 'ADD_ROOM',
    payload: room,
});

export const setRooms = (rooms) => ({
    type: 'SET_ROOMS',
    payload: rooms,
});

export const addConnectedUser = (room_id, user) => ({
    type: 'ADD_CONNECTED_USER',
    payload: {
        room_id,
        user
    }
});

export const removeConnectedUser = (room_id, user_id) => ({
    type: 'REMOVE_CONNECTED_USER',
    payload: {
        room_id,
        user_id
    }
});

export const updateRoomById = (data) => ({
    type: 'UPDATE_ROOM_BY_ID',
    payload: data
});

export const updateRoomAccess = (data) => ({
    type: 'UPDATE_ROOM_ACCESS',
    payload: data
});

export const removeUserFromBlackList = (room_id, user_id) => ({
    type: 'REMOVE_USER_FROM_BLACK_LIST',
    payload: { room_id, user_id }
});

export const removeRoom = (roomId) => ({
    type: 'REMOVE_ROOM',
    payload: roomId
});

export const startLoading = () => ({
    type: 'START_LOADING'
});

export const stopLoading = () => ({
    type: 'STOP_LOADING'
});