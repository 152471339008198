import {JitsiMeeting} from "@jitsi/react-sdk";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export const JitsiRoom = () => {
    const navigate = useNavigate();
    const [api, setApi] = useState(null);
    //const user = useSelector(state => state.user);
    const user  = {
        name: `Dmytro Movchan${Math.floor(Math.random() * 10)}`,
        avatar: 'https://lh3.googleusercontent.com/a/ACg8ocLtzpT-ATbbRLx6_OQuEx4Yb5GrVBw0_ozeufVrLESie9iJQ1M=s96-c'
    }

    useEffect(() => {
        if (api) {
            // Подписка на событие выхода из конференции
            api.addListener('readyToClose', () => {
                // Редирект на главную страницу
                navigate('/');
            });
        }
    }, [api, navigate]);

    return (
        <div style={{ height: "100vh", display: "grid", flexDirection: "column", overflow: "hidden" }}>
            <JitsiMeeting
                domain = { "speak-stream-meet.com" }
                roomName = "room1"
                configOverwrite = {{
                    startWithAudioMuted: true,
                    disableModeratorIndicator: true,
                    startScreenSharing: true,
                    enableEmailInStats: false,
                    disableProfile: true,
                    disableDeepLinking: true,
                    hiddenPremeetingButtons: ['invite'],
                    customToolbarButtons: [
                        {
                            icon: 'https://cdn4.iconfinder.com/data/icons/24x24-free-pixel-icons/24/Accept.png',
                            id: 'custom-button-1',
                            text: 'My Button',
                            backgroundColor: '#f00'
                        }
                    ]
                }}
                interfaceConfigOverwrite = {{
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                    TOOLBAR_BUTTONS: [
                        'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
                        'fodeviceselection', 'hangup', 'chat', 'recording', 'livestreaming',
                        'etherpad', 'sharedvideo', 'settings', 'videoquality', 'filmstrip',
                        'shortcuts', 'tileview', 'videobackgroundblur', 'help', 'mute-everyone',
                        'security', 'whiteboard', 'reactions', 'virtualBackgrounds', 'custom-button-1'
                    ],
                }}

                userInfo = {{
                    displayName: user.name,
                    avatarUrl: user.avatar,
                }}
                onApiReady = { (externalApi) => {
                    setApi(externalApi);
                    // Добавление обработчика событий для кастомной кнопки
                    externalApi.addListener('toolbarButtonClicked', (event) => {
                        if (event.key === 'custom-button-1') {
                            // Ваша логика при нажатии на кнопку
                            alert('Custom button clicked');
                            // Например, открытие модального окна, отправка сообщения и т.д.
                        }
                    });
                } }
                containerStyles={{display: "flex", flex: 1}}
            />
        </div>
    );
}