const initialState = [{
    id: null,
    type: ""
}];

export const rouletteTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ROULETTE_TYPES':
            return action.payload;
        default:
            return state;
    }
};