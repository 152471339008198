import {InfoCircle} from "react-bootstrap-icons";
import React, {useEffect, useState} from "react";

export const PopoverOwnRoom = ({ owner }) => {
    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const toggleOpenPopover= () => {
        setIsOpenPopover(!isOpenPopover);
    };
    useEffect(() => {
        const handleOutsideClick = (event) => {
            // Проверяем, было ли кликнуто вне popover
            if (isOpenPopover && !event.target.closest('.tb-pop-container')) {
                setIsOpenPopover(false);
            }
        };

        // Добавляем обработчик события для клика по всему документу
        document.addEventListener('click', handleOutsideClick);

        return () => {
            // Удаляем обработчик события при размонтировании компонента
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpenPopover]);

    return (
        <div className="tb-pop-container" onClick={toggleOpenPopover}>
            <div className={`tb-popover-wrapper ${isOpenPopover ? 'open' : ''}`}>
                <div>
                    <InfoCircle size={24} />
                </div>
                <div className="tb-popover-content">
                        <ul className="tb-popover-message">
                            <li className="text-underline">Room owner</li>
                            <img src={owner.avatar}
                                 alt="Web Development Agency | TechieBundle" />
                            <li>{owner.name}</li>
                            {owner.country && (
                                <li>Country: {owner.country}</li>
                            )}
                            <li>Created at: </li>
                            <li>{owner.created_at}</li>
                        </ul>
                </div>
            </div>
        </div>
    );
};