const initialState = {
    data: [],
    loading: true
};

export const roomReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_ROOM':
            const {
                room_id,
                owner,
                language_id,
                language,
                level_id,
                level,
                quantity,
                description,
                access,
                black_list,
                created_at,
                updated_at,
                connected_users,
                connected_users_count
            } = action.payload;

            return {
                ...state,
                data: [
                    {
                        room_id,
                        owner,
                        language_id,
                        language,
                        level_id,
                        level,
                        quantity,
                        description,
                        access,
                        black_list,
                        created_at,
                        updated_at,
                        connected_users,
                        connected_users_count
                    },
                    ...state.data
                ]
            };

        case 'SET_ROOMS':
            return {
                ...state,
                data: action.payload,
                loading: false
            };

        case 'ADD_CONNECTED_USER':
            return {
                ...state,
                data: state.data.map(room => {
                    if (room.room_id === action.payload.room_id) {
                        const userExists = room.connected_users.some(user => user.id === action.payload.user.id);
                        if (!userExists) {
                            const newConnectedUsers = [
                                ...room.connected_users,
                                action.payload.user
                            ];
                            return {
                                ...room,
                                connected_users: newConnectedUsers,
                                connected_users_count: newConnectedUsers.length
                            };
                        }
                    }
                    return room;
                })
            };

        case 'REMOVE_CONNECTED_USER':
            return {
                ...state,
                data: state.data.map(room => {
                    if (room.room_id === action.payload.room_id) {
                        const newConnectedUsers = room.connected_users.filter(user => user.id !== Number(action.payload.user_id));
                        return {
                            ...room,
                            connected_users: newConnectedUsers,
                            connected_users_count: newConnectedUsers.length
                        };
                    }
                    return room;
                })
            };

        case 'UPDATE_ROOM_BY_ID':
            return {
                ...state,
                data: state.data.map(room =>
                    room.room_id === action.payload.room_id ?
                        { ...room, ...action.payload } :
                        room
                )
            };

        case 'UPDATE_ROOM_ACCESS':
            return {
                ...state,
                data: state.data.map(room =>
                    room.room_id === action.payload.room_id ?
                        { ...room, access: action.payload.access } :
                        room
                )
            };

        case 'REMOVE_USER_FROM_BLACK_LIST':
            return {
                ...state,
                data: state.data.map(room => {
                    if (room.room_id === action.payload.room_id) {
                        const newBlackList = room.black_list.filter(userId => userId !== action.payload.user_id);
                        return {
                            ...room,
                            black_list: newBlackList
                        };
                    }
                    return room;
                })
            };

        case 'REMOVE_ROOM':
            return {
                ...state,
                data: state.data.filter(room => room.room_id !== action.payload)
            };

        case 'START_LOADING':
            return {
                ...state,
                loading: true
            };

        case 'STOP_LOADING':
            return {
                ...state,
                loading: false
            };

        default:
            return state;
    }
};