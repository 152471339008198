import http from "./index";


// аутентифицировать пользователя
export const userAuth = async (code) => {
    try {
        const res = await http.post('auth', {code: code});
        return res.data;
    } catch (err) {
        throw err;
    }
}


// разлогинить пользователя
export const userLogout = async () => {
    try {
        const res = await http.post('users/logout');
        return res.data;
    } catch (err) {
        throw err;
    }
}

// получить аутентифицированного пользователя
export const getUserAuth = async () => {
    try {
        const res = await http.get('get-user-auth');
        return res.data;
    } catch (err) {
        throw err;
    }
}

// обновить страну пользователя
export const updateUserCountryRequest = async (country_id) => {
    try {
        const res = await http.put('user-country', country_id);
        return res.data;
    } catch (err) {
        throw err;
    }
}
