export const setPrivateChats = (data) => ({
    type: 'SET_PRIVATE_CHATS',
    payload: data,
});

export const addMessageToPrivateChat = (user, messages) => ({
    type: 'ADD_MESSAGE_TO_PRIVATE_CHAT',
    payload: { user, messages }
});

export const markMessagesAsRead = (user_id, messageIds) => ({
    type: 'MARK_MESSAGES_AS_READ',
    payload: { user_id, messageIds }
});

export const deletePrivateChat = (user_id) => ({
    type: 'DELETE_PRIVATE_CHAT',
    payload: { user_id }
});