import './App.css';
import {BrowserRouter} from "react-router-dom";
import {WSProvider} from "./context/WSContext";
import React from "react";
import RouterComponent from "./components/RouterComponent";
import {BasicComponent} from "./components/BasicComponent";
import {ModalProvider} from "./context/ModalContext";
function App() {
  return (
      <BrowserRouter>
          <WSProvider>
              <ModalProvider>
                  <BasicComponent />
                  <RouterComponent />
              </ModalProvider>
          </WSProvider>
      </BrowserRouter>
  );
}

export default App;