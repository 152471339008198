import {
    ArrowUpCircle,
    BoxArrowRight,
    CreditCard2Front,
    Flag,
    Palette, Pencil,
    PeopleFill,
    PersonHeart, PersonPlusFill, PersonSquare
} from "react-bootstrap-icons";
import {format} from "date-fns";
import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllUsersWithFriendshipAndRoomStatus} from "../../redux/selectors/followsSelectors";
import {useAuth} from "../../hooks/useAuth";
import {ToggleButton} from "../buttons/ToggleButton";
import {useDropdownList} from "../../hooks/useDropdownList";
import {DropdownList} from "../DropdownList";
import {updateUserCountryRequest} from "../../axios/userRequests";
import {Slide, toast} from "react-toastify";
import {updateUserCountry} from "../../redux/actions/user";
import FlagIcon from 'react-world-flags'

export const AuthProfile = () => {
    const dispatch = useDispatch();
    const offcanvasRef = useRef(null);
    const user = useSelector(state => state.user);
    const countries = useSelector(state => state.countries);
    const [activeEdit, setActiveEdit] = useState(false);
    const {
        followers_count,
        following_count,
        friends_count
    } = useSelector(state => getAllUsersWithFriendshipAndRoomStatus(state, user.id));
    const additionalSuccessAction = () => {
        if (offcanvasRef.current) {
            offcanvasRef.current.click();
        }
    };
    const {logOut} = useAuth(additionalSuccessAction);
    const {
        isOpenDropdownCountry,
        selectedCountry,
        toggleDropdownCountry,
        handleCountryClick
    } = useDropdownList();

    const sendUpdateCountry = async () => {
        setActiveEdit(false);
        if (user.country_id === selectedCountry.id) {
            return;
        }
        try {
            await updateUserCountryRequest({country_id: selectedCountry.id});
            dispatch(updateUserCountry(selectedCountry));
            toast.success('Country updated.', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        } catch (e) {
            console.log(e);
            toast.error('Something went wrong...', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
            });
        }
    }

    return (
        <div className="offcanvas offcanvas-top offcanvas-size-profile bg-dark-modal text-white border-modal" tabIndex="-1" id="offcanvasAuthProfile"
             aria-labelledby="offcanvasAuthProfile">
            <div className="offcanvas-body">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="profile-header d-flex align-items-end">
                        <PersonSquare size={30}/> <span className='mx-2' onClick={() => console.log(user)}>Profile</span>
                    </div>
                    <div data-bs-theme="dark">
                        <button ref={offcanvasRef} type="button" className="btn-close" data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                    </div>
                </div>
                <hr/>
                <div className="center bg-dark-modal">
                    <div className="profile">
                        <div className="profile-image">
                            <img
                                src={user.avatar}
                                alt="Avatar"/>
                        </div>
                        <div className="name">{user.name}</div>
                        <div className="user-auth-info">{user.email}</div>
                        <div className="user-auth-info">Created
                            At: {format(user.created_at, 'yyyy-MM-dd HH:mm:ss')}</div>
                        <hr className="hr-user-info"/>
                        <ul className="auth-user-menu">
                            <li onClick={() => console.log(countries)}><CreditCard2Front size={18}/> 365 days</li>
                            <li className="d-flex justify-content-between">
                                {!activeEdit ?
                                    <React.Fragment>
                                        <div>
                                            <Flag size={18}/> <FlagIcon code={selectedCountry.code}  width='25' height='18' /> {user.country}
                                        </div>
                                        <div className="text-center edit-user-data"
                                            onClick={() => setActiveEdit(true)}>
                                            <Pencil />
                                        </div>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div>
                                            <Flag size={18}/>
                                        </div>
                                        <DropdownList
                                            selectedItem={
                                                <>
                                                    <FlagIcon code={selectedCountry.code} className="mx-1"  width='25' height='18' />
                                                    {selectedCountry.country}
                                                </>
                                            }
                                            items={countries.map((country) => (
                                                <>
                                                    <FlagIcon code={country.code} className="mx-1"  width='25' height='18' />
                                                    {country.country}
                                                </>
                                            ))}
                                            isOpen={isOpenDropdownCountry}
                                            onToggleDropdown={toggleDropdownCountry}
                                            onItemClick={handleCountryClick}
                                            errorClass=""
                                            classDrop="country"
                                        />

                                        <div className="text-center edit-user-data"
                                             onClick={sendUpdateCountry}>
                                            <ArrowUpCircle />
                                        </div>
                                    </React.Fragment>
                                }
                            </li>
                            <li><Palette size={18}/> Theme: <div className="d-inline-block auth-theme"><ToggleButton
                                text1='Light' text2='Dark' toggle={false}/></div></li>
                            <hr className="hr-user-menu"/>
                            <li className="user-logout" onClick={logOut}><BoxArrowRight size={18}/> logout</li>
                        </ul>
                    </div>
                    <div className="stats">
                        <div className="box">
                            <span className="parameter text-danger"><PeopleFill size={18}/></span>
                            <span className="value">{friends_count}</span>
                            <span className="parameter">Friends</span>
                        </div>
                        <div className="box">
                            <span className="parameter text-danger"><PersonHeart size={18}/></span>
                            <span className="value">{followers_count}</span>
                            <span className="parameter">Followers</span>
                        </div>
                        <div className="box">
                            <span className="parameter text-danger"><PersonPlusFill size={18}/></span>
                            <span className="value">{following_count}</span>
                            <span className="parameter">Following</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}