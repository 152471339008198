import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [isModalInModal, setIsModalInModal] = useState(false);

    return (
        <ModalContext.Provider value={{ isModalInModal, setIsModalInModal }}>
            {children}
        </ModalContext.Provider>
    );
};

export const useModalContext = () => useContext(ModalContext);