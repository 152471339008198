import {useCallback, useContext, useState} from "react";
import {RoomContext} from "../../context/RoomContext";
import {Wheel} from "react-custom-roulette";
import {useSelector} from "react-redux";
import {updatedItem} from "../../utils/rouletteHelpers";
import {ButtonPurpleBorder} from "../buttons/ButtonPurleBorder";

export const RouletteUsers = () => {
    const {rouletteMap, isSpinning, rouletteResult, items} = useContext(RoomContext);
    const user = useSelector(state => state.user);

    const [selectedOption, setSelectedOption] = useState('')

    const handleSpinClick = () => {
        if (!isSpinning) {
            const newPrizeNumber = Math.floor(Math.random() * items.length);

            rouletteMap.current.set('roulette', {
                ...rouletteMap.current.get('roulette'),
                rouletteResult: newPrizeNumber,
                isSpinning: true
            });
        }
    }

    const handleStopSpinning = () => {
        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            isSpinning: false
        });
        setSelectedOption(items[rouletteResult].option)
    }

    const joinToRoulette = () => {
        let newItems = [...items];
        const newItem = updatedItem({ option: user.name  })
        if (newItems.length === 2 && newItems[0].option === "Roulette" && newItems[1].option === "Users") {
            newItems = [newItem];
        } else {
            newItems = [...newItems, newItem];
        }

        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            items: newItems
        });
    };

    const exitFromRoulette = () => {
        let newItems = items.filter(item => item.option !== user.name);

        if (newItems.length === 0) {
            newItems = [{ option: "Roulette" }, { option: "Users" }];
        }

        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            items: newItems.length === 0 ? [{ option: "Roulette" }, { option: "Users" }] : newItems
        });
    };

    const isUserInRoulette = () => {
        return items.some(item => item.option === user.name);
    };

    const changeRoulette = useCallback(() => {
        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            stepRoulette: 'list',
            rouletteType: null,
            isSpinning: false,
            items: []
        });
    }, [rouletteMap]);

    return (
        <div>
            <div className="text-center m-2">
                <button type="button" className="btn btn-secondary width-150px" onClick={changeRoulette}>Change roulette</button>
                {!isUserInRoulette() ?
                    <button type="button" className="btn btn-primary mx-2 width-150px" disabled={isSpinning} onClick={joinToRoulette}>Join</button>
                    :
                    <button type="button" className="btn btn-primary mx-2 width-150px" disabled={isSpinning} onClick={exitFromRoulette}>Exit</button>
                }
            </div>
            <div className="d-flex justify-content-center">
                <Wheel
                    mustStartSpinning={isSpinning}
                    prizeNumber={rouletteResult}
                    data={items}
                    onStopSpinning={handleStopSpinning}
                    backgroundColors={['#00c75d', '#ff602e']}
                    textColors={['#ffffff']}
                    textDistance={55}
                    spinDuration={0.5}
                />
            </div>
            <div className="text-center m-2">
                <ButtonPurpleBorder
                    onClick={handleSpinClick}
                    text='SPIN'
                    className='width-250px'
                />
            </div>
            <p className="text-white mx-2">Result: {selectedOption && selectedOption}</p>
        </div>
    )
}