import axios from "axios";
import {getItemWithExpiry, setItemWithExpiry} from "../utils/localStorage";

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});
export const debug = [];

// Перехватчик для запросов
http.interceptors.request.use(
    (config) => {
        const token = getItemWithExpiry('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Перехватчик для ответов
http.interceptors.response.use(
    (response) => {
        // Проверяем наличие заголовка updateToken
        if (response.headers.has('update-token')) {
            // Выполняем необходимое действие
            setItemWithExpiry('token', response.headers.get('update-token'), 2);
        }
        debug.push({res: response});
        return response;
    },
    (error) => {
        // Обработка ошибок
        debug.push({err: error});
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

export default http;