import http from "./index";

// добавить пользователя в black list
export const saveUserInBlackList = async (data) => {
    try {
        return await http.post('black-list', data);
    } catch (err) {
        throw err;
    }
}

// удалить пользователя с black list
export const deleteUserFromBlackList = async (data) => {
    try {
        return await http.delete('black-list', {
            data: {
                room_id: data.room_id,
                user_id: data.user_id
            }
        });
    } catch (err) {
        throw err;
    }
}