import React, {createContext} from "react";
import socketIOClient from "socket.io-client";

const WS = process.env.REACT_APP_WS_HOST;

export const WSContext = createContext(null);

const ws = socketIOClient(WS);

export const WSProvider = ({children}) => {

    return (
        <WSContext.Provider value={{ws}}>
            {children}
        </WSContext.Provider>
    );
};