const initialRoomState = {
    room_id: null,
    owner: null,
    language_id: null,
    language: null,
    level_id: null,
    level: null,
    quantity: 0,
    description: '',
    access: '',
    black_list: [],
    created_at: null,
    updated_at: null,
    connected_users: [],
    connected_users_count: 0
};

export const oneRoomReducer = (state = initialRoomState, action) => {
    switch (action.type) {
        case 'SET_ROOM':
            return action.payload;

        case 'ADD_CONNECTED_USER':
            const newConnectedUsers = [
                ...state.connected_users,
                action.payload.user
            ];
            return {
                ...state,
                connected_users: newConnectedUsers,
                connected_users_count: newConnectedUsers.length
            };

        case 'REMOVE_CONNECTED_USER':
            const newConnectedUsersAfterRemove = state.connected_users.filter(user => user.id !== Number(action.payload.user_id));
            return {
                ...state,
                connected_users: newConnectedUsersAfterRemove,
                connected_users_count: newConnectedUsersAfterRemove.length
            };

        case 'UPDATE_ROOM':
            return {
                ...state,
                ...action.payload
            };

        case 'UPDATE_ROOM_ACCESS':
            return {
                ...state,
                access: action.payload.access
            };

        case 'ADD_USER_TO_BLACK_LIST': {
            const newBlackList = [
                ...state.black_list,
                action.payload
            ];
            return {
                ...state,
                black_list: newBlackList
            };
        }

        case 'REMOVE_USER_FROM_BLACK_LIST': {
            const newBlackListAfterRemove = state.black_list.filter(user_id => user_id !== action.payload);
            return {
                ...state,
                black_list: newBlackListAfterRemove
            };
        }

        case 'REMOVE_ROOM':
            return initialRoomState;

        default:
            return state;
    }
};
