import React from "react";

export const DropdownList = ({
                             selectedItem,
                             items,
                             isOpen,
                             onToggleDropdown,
                             onItemClick,
                             errorClass,
                             classDrop
                         }) => {
    return (
        <div className="dropdown w-100">
            <button
                className={`dropbtn drop-${classDrop} w-100 font ${errorClass}`}
                onClick={onToggleDropdown}>
                {selectedItem}
            </button>
            <div className={`w-100 dropdown-content ${classDrop} dropdown-content-${classDrop} ${isOpen ? 'open' : ''}`}>
                <ul>
                    {items.map((item, id) => (
                        <li className="font" key={id} onClick={() => onItemClick(id)}>
                            {item}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};