import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {RoomContext} from "../../context/RoomContext";
import {getRouletteRequest} from "../../axios/rouletteRequests";

export const RouletteList = () => {
    const room = useSelector(state => state.oneRoom);
    const rouletteTypes = useSelector(state => state.rouletteTypes);
    const {rouletteMap} = useContext(RoomContext);

    const startRoulette = async (type) => {
        try {
            rouletteMap.current.set('roulette', {
                ...rouletteMap.current.get('roulette'),
                stepRoulette: 'loading'
            });
            const data = {
                type_id: type.id,
                topic: type.type,
                language: room.language
            }

            const rouletteResult = await getRouletteRequest(data);

            rouletteMap.current.set('roulette', {
                ...rouletteMap.current.get('roulette'),
                stepRoulette: 'spin',
                rouletteType: type.type,
                items: rouletteResult,
            });
        } catch (e) {
            console.log(e);
            rouletteMap.current.set('roulette', {
                ...rouletteMap.current.get('roulette'),
                stepRoulette: 'list',
                rouletteType: '',
                items: [],
            });
        }
    }

    const startCustomRoulette = () => {
        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            stepRoulette: 'custom',
            items: [{"option": "Roulette"}, {"option": "Custom"}]
        });
    }

    const startUsersRoulette = () => {
        rouletteMap.current.set('roulette', {
            ...rouletteMap.current.get('roulette'),
            stepRoulette: 'users',
            items: [{"option": "Roulette"}, {"option": "Users"}]
        });
    }

    return (
        <div className="d-flex justify-content-center">
            <div className="wrapper-list-quizzes my-3">
                <p className="list-roulette pointer" onClick={startCustomRoulette}>
                    1. Custom Roulette
                </p>
                <p className="list-roulette pointer" onClick={startUsersRoulette}>
                    2. Users Roulette
                </p>
                {rouletteTypes.map((type, index) => (
                    <p key={index} className="list-roulette pointer" onClick={() => startRoulette(type)}>
                        {index + 3}. {type.type}
                    </p>
                ))}
            </div>
        </div>
    );
}