import React, {useCallback, useContext} from "react";
import {RoomContext} from "../../context/RoomContext";
import {useSelector} from "react-redux";
import {getQuizRequest} from "../../axios/quizRequests";

export const QuizJoin = () => {
    const {quizMap, usersQuiz, quizType} = useContext(RoomContext);
    const user = useSelector(state => state.user);
    const room = useSelector(state => state.oneRoom);

    const isUserInQuiz = useCallback(() => {
        return usersQuiz.some(userInQuiz => userInQuiz.id === user.id);
    }, [usersQuiz, user.id]);

    const joinToQuiz = useCallback(() => {
        if (!isUserInQuiz()) {
            const joinedUser = {
                id: user.id,
                name: user.name,
                avatar: user.avatar,
                numberQuestion: 1,
                score: 0,
                finish: false,
            };
            const updateUserQuiz = [...usersQuiz, joinedUser];

            quizMap.current.set('quiz', {
                ...quizMap.current.get('quiz'),
                usersQuiz: updateUserQuiz
            });
        }
    }, [isUserInQuiz, user, usersQuiz, quizMap]);

    const exitFromQuiz = useCallback(() => {
        const updateUserQuiz = usersQuiz.filter(userInQuiz => userInQuiz.id !== user.id);

        quizMap.current.set('quiz', {
            ...quizMap.current.get('quiz'),
            usersQuiz: updateUserQuiz
        });
    }, [user.id, usersQuiz, quizMap]);

    const startQuiz = async () => {
        if (usersQuiz.length === 0) return;
        try {
            quizMap.current.set('quiz', {
                ...quizMap.current.get('quiz'),
                stepQuiz: 'loading'
            });
            const data = {
                type_id: quizType.id,
                type: quizType.type,
                description: quizType.description,
                language: room.language
            }
            const [quizResult] = await Promise.all([
                getQuizRequest(data),
                new Promise(resolve => setTimeout(resolve, 1000))
            ]);
            quizMap.current.set('quiz', {
                ...quizMap.current.get('quiz'),
                stepQuiz: 'in-progress',
                quiz: quizResult,
            });
        } catch (e) {
            console.log(e);
            quizMap.current.set('quiz', {
                ...quizMap.current.get('quiz'),
                stepQuiz: 'join',
                quiz: [],
            });
        }
    }

    const changeQuiz = useCallback(() => {
        quizMap.current.set('quiz', {
            ...quizMap.current.get('quiz'),
            stepQuiz: 'list',
            quiz: [],
            quizType: null,
            usersQuiz: []
        });
    }, [quizMap]);

    return (
        <div className="d-flex flex-column quiz-step-join">
            <div className="d-grid gap-4 col-6 mx-auto">
                <button className="btn btn-primary" type="button" onClick={changeQuiz}>Change quiz</button>
                {!isUserInQuiz() ?
                    <button className="btn btn-danger" type="button" onClick={joinToQuiz}>Join</button>
                    :
                    <button className="btn btn-danger" type="button" onClick={exitFromQuiz}>Exit</button>
                }

                <button className="btn btn-success" type="button" disabled={!isUserInQuiz()} onClick={startQuiz}>Start
                </button>
            </div>
            <div className="mx-auto border-bottom w-100 max-width-600px">
                <div className="m-2 text-center text-white">
                    {quizType.description}
                </div>
            </div>
            <div className="m-3">
                <div className="d-flex justify-content-center ">
                    <p className="quiz-list-users-header">List users: {usersQuiz.length}</p>
                </div>
                <div className="d-flex justify-content-center row">
                    <div className="col-md-6 col-12">
                        {usersQuiz.map((userInQuiz, index) => (
                            <div className="d-flex align-items-center invite-user-content my-2 border-bottom"
                                 key={index}>
                                <img src={userInQuiz.avatar} alt="avatar" className="m-2"/>
                                <p className="m-0 text-white">{userInQuiz.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}