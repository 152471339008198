import {useSelector} from "react-redux";
import React, {useCallback, useContext} from "react";
import {RoomContext} from "../../context/RoomContext";

export const QuizList = () => {
    const quizTypes = useSelector(state => state.quizTypes);
    const {quizMap} = useContext(RoomContext);

    const startQuiz = useCallback((type) => {
        const quizType= {
            id: type.id,
            type: type.type,
            description: type.description
        }
        quizMap.current.set('quiz', {
            ...quizMap.current.get('quiz'),
            quizType: quizType,
            stepQuiz: 'join'
        });
    }, [quizMap]);

    return (
        <div className="d-flex justify-content-center">
            <div className="wrapper-list-quizzes my-3">
                {quizTypes.map((type, index) => (
                    <p key={index} className="list-roulette pointer" onClick={() => startQuiz(type)}>
                        {index + 1}. {type.type}
                    </p>
                ))}
            </div>
        </div>
    );
}